@charset "UTF-8";
@import url("//fonts.googleapis.com/css2?family=Noto+Sans+TC&display=swap");
@import url("//fonts.googleapis.com/css2?family=Montserrat&display=swap");
@import url("//fonts.googleapis.com/css2?family=Inter:wght@200&display=swap");
@import url("https://use.typekit.net/ndx1aeo.css");
@import url("//fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap");
@import url("//fonts.googleapis.com/css2?family=Noto+Serif+TC:wght@500&display=swap");
a, body {
  color: #000;
  font-size: 16px;
  font-family: "Noto Sans TC", "Microsoft JhengHei", "微軟正黑體", "Arial", sans-serif;
  -webkit-text-stroke: 0.1px;
  line-height: 1.8;
  font-weight: 400;
}

.block-title-box .txt.en {
  font-family: turbinado-pro, sans-serif;
  font-weight: 300;
  font-style: normal;
}

.sprite, input, form, body, html {
  margin: 0px;
  padding: 0px;
}

.contact-bg, .image-slider .swiper-slide .img-container {
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
}

.content {
  display: flex;
  flex-wrap: wrap;
}

.contact-form .form-group, .btn-wrap, .radio_style {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.noJS > div,
.noJS > ul {
  display: none;
}

.noJS > noscript {
  display: block;
  width: 100%;
  line-height: 10em;
}

* {
  box-sizing: border-box;
}

html {
  overflow: -moz-scrollbars-vertical;
}

body {
  background: #fff;
  visibility: visible;
}
body.fixed {
  overflow: hidden;
}

div {
  position: relative;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding-left: 0;
}

.sh_sans {
  font-family: "source-han-sans-traditional", "微軟正黑體", sans-serif;
}

.sh_serif {
  font-family: "source-han-serif-tc", serif;
}

form {
  font-size: 16px;
}

label {
  display: inline-flex;
  align-items: center;
  margin-right: 12px;
  cursor: pointer;
}

input {
  -webkit-autofill: unset;
  font-size: 16px;
  background-color: #eeeeee;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #fcfcfc inset;
}
input:focus {
  outline: 0;
}
input[type=text], input[type=password] {
  vertical-align: top;
}
input[type=radio], input[type=checkbox] {
  margin-right: 5px;
  cursor: pointer;
  vertical-align: middle;
}
input[type=number]::-webkit-inner-spin-button,
input [type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
input.hid {
  display: none;
}

a:focus,
input:focus,
select:focus,
button:focus {
  box-shadow: none !important;
  outline: none;
}

select {
  border: 0px;
  outline: none;
}

textarea {
  padding: 5px;
  border: 1px solid rgba(216, 216, 216, 0.8);
  box-sizing: border-box;
  resize: none;
  font-size: 16px;
  background-color: #eeeeee;
}

a {
  cursor: pointer;
  color: inherit;
  font-size: inherit;
  text-decoration: none;
}
a:hover, a:focus {
  text-decoration: none;
  color: currentColor;
}

img {
  max-width: 100%;
}

.sprite {
  fill: #A86B4F;
  vertical-align: top;
  width: 100%;
  height: 100%;
  transition: 0.3s ease-in-out;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.font-button .far,
.font-button .fas {
  color: #666;
  transition: 0.08s cubic-bezier(0.37, 0.99, 0.92, 0.96);
}
.font-button:hover .far,
.font-button:hover .fas {
  color: #A86B4F;
}

.zero-size {
  font-size: 0px;
  letter-spacing: 0px;
  word-spacing: 0px;
}

.required {
  color: #A86B4F;
  margin: 0 0 0 8px;
}

.red {
  color: #ff0016;
}

.space {
  display: inline-block;
  width: 7px;
}

.clear {
  clear: both;
  height: 0px;
  line-height: 0px;
  font-size: 0px;
  overflow: hidden;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.hide {
  display: none !important;
}
@media (max-width: 1000px) {
  .hide {
    display: block !important;
  }
}

@keyframes menushow {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes visitsLoad {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  30% {
    transform: translate3d(-70%, 0, 0);
  }
  80% {
    transform: translate3d(-20%, 0, 0);
  }
  95% {
    transform: translate3d(-5%, 0, 0);
  }
  100% {
    transform: translate3d(0%, 0, 0);
  }
}
@keyframes visitsShow {
  0% {
    opacity: 0;
    transform: translate3d(0, 45px, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes moveCircle {
  0%, 100% {
    transform: translateY(-10%);
  }
  50% {
    transform: translateY(-5%);
  }
}
.table {
  border-top: 1px solid #c8dae8;
  border-right: 1px solid #c8dae8;
  border-left: 1px solid #c8dae8;
}

.input {
  width: 100%;
}

.textarea {
  width: 100%;
  height: 100px;
}

.searchBar {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.icon {
  display: inline-block;
  vertical-align: middle;
}

.wrapper-1800 {
  width: 100%;
  max-width: 1800px;
  margin: auto;
}

.wrapper-1750 {
  width: 100%;
  max-width: 1750px;
  margin: auto;
}

.wrapper-1700 {
  width: 100%;
  max-width: 1700px;
  margin: auto;
}

.wrapper-1650 {
  width: 100%;
  max-width: 1650px;
  margin: auto;
}

.wrapper {
  width: 100%;
  max-width: 1600px;
  margin: auto;
}

.wrapper-1550 {
  width: 100%;
  max-width: 1550px;
  margin: auto;
}

.wrapper-1500 {
  width: 100%;
  max-width: 1500px;
  margin: auto;
}

.wrapper-1450 {
  width: 100%;
  max-width: 1450px;
  margin: auto;
}

.wrapper-1400 {
  width: 100%;
  max-width: 1400px;
  margin: auto;
}

.wrapper-1350 {
  width: 100%;
  max-width: 1350px;
  margin: auto;
}

.wrapper-1300 {
  width: 100%;
  max-width: 1300px;
  margin: auto;
}

.wrapper-1250 {
  width: 100%;
  max-width: 1250px;
  margin: auto;
}

.wrapper-1200 {
  width: 100%;
  max-width: 1200px;
  margin: auto;
}

.wrapper-1100 {
  width: 100%;
  max-width: 1100px;
  margin: auto;
}

.wrapper-1000 {
  width: 100%;
  max-width: 1000px;
  margin: auto;
}

.wrapper-900 {
  width: 100%;
  max-width: 900px;
  margin: auto;
}

.wrapper-800 {
  width: 100%;
  max-width: 800px;
  margin: auto;
}

.detail_label {
  display: inline-block;
  vertical-align: middle;
  margin-top: -3px;
  padding: 0 6px;
  height: 20px;
  text-align: center;
  background-color: #fff;
  line-height: 20px;
  vertical-align: middle;
  font: 13px/25px Arial;
  border: 1px solid #fff;
}
.detail_label.fc-LightSalmon {
  border-color: #e9a3a8;
  color: #e9a3a8;
  line-height: 20px;
}

/* #Navigation
================================================== */
.navigation-wrap {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1050;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.navbar {
  padding: 0;
}
.navbar-brand {
  margin-right: 0;
}
.navbar-light .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 1px solid #000;
  transition: all 300ms linear;
}
.navbar-light .navbar-toggler-icon::after, .navbar-light .navbar-toggler-icon::before {
  width: 24px;
  position: absolute;
  height: 1px;
  background-color: #000;
  top: 0;
  left: 0;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}
.navbar-light .navbar-toggler-icon::after {
  top: 8px;
}
.navbar-toggler {
  position: absolute;
  right: 0;
  top: 2rem;
  border: none;
  padding-right: 0;
}
.navbar-toggler:active, .navbar-toggler:focus {
  outline: none;
}
.navbar-toggler[aria-expanded=true] .navbar-toggler-icon:after {
  transform: rotate(45deg);
}
.navbar-toggler[aria-expanded=true] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
}
.navbar-toggler[aria-expanded=true] .navbar-toggler-icon {
  border-color: transparent;
}
@media (min-width: 1001px) {
  .navbar {
    justify-content: space-between;
  }
  .navbar-brand, .navbar-link, .navbar-marquee {
    flex: auto;
  }
  .navbar-link {
    order: -1;
  }
  .navbar-marquee {
    flex: 0 0 calc(250px + 1rem);
    max-width: calc(250px + 1rem);
    padding-left: 1rem;
    padding-right: 1rem;
    color: #444343;
    border-right: #A86B4F 1px solid;
    border-left: #A86B4F 1px solid;
  }
}
@media (min-width: 1100px) {
  .navbar-marquee {
    flex: 0 0 calc(400px + 1rem);
    max-width: calc(400px + 1rem);
  }
}
@media (min-width: 1500px) {
  .navbar-marquee {
    font-size: 1.0526315789vw;
  }
}
@media (max-width: 810px) {
  .navbar {
    flex-direction: column;
    align-items: flex-start;
  }
  .navbar-link {
    order: 2;
  }
  .navbar-marquee {
    width: 100%;
    padding: 5px 10px;
  }
}
@media (min-width: 800px) and (max-width: 1025px) {
  .navbar-link {
    font-size: 18px;
    font-size: 1.125rem;
  }
}
@media (min-width: 750px) and (max-width: 1000px) {
  .navbar-link {
    position: absolute;
    right: 1em;
    top: 2.5em;
  }
  .navbar-marquee {
    position: absolute;
    right: 1em;
    top: 0em;
    max-width: 20em;
  }
}
@media (max-width: 640px) {
  .navbar-link,
.navbar-link .navbar-nav {
    width: 100%;
  }
  .navbar-link .navbar-nav {
    flex-direction: row;
    justify-content: space-between;
  }
}

.nav-link {
  position: relative;
  padding: 5px 0 !important;
  display: inline-block;
  color: #212121 !important;
  font-weight: 500;
  transition: all 200ms linear;
}
@media (min-width: 1200px) {
  .nav-link {
    font-size: 1.4736842105vw;
  }
}
@media (min-width: 1500px) {
  .nav-link {
    font-size: 1.0526315789vw;
  }
}
.nav-item {
  position: relative;
  transition: all 200ms linear;
  padding-right: 1.8rem;
  padding-left: 1.8rem;
}
.nav-item.active .nav-link, .nav-item:hover .nav-link {
  color: #A86B4F !important;
}
.nav-item-lang::after {
  display: none;
}
@media (min-width: 1001px) {
  .nav-item {
    border-left: #A86B4F 1px solid;
  }
}
@media (max-width: 1025px) {
  .nav-item {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}
.nav-lang {
  display: flex;
  align-items: center;
  color: #A86B4F;
  line-height: 27px;
  font-size: 16px;
}
.nav-lang .icon {
  display: inline-block;
  flex: none;
  margin-right: 0.45rem;
  margin-top: -2px;
}
@media (min-width: 1001px) {
  .nav-lang {
    border-radius: 20px;
    border: #A86B4F 1px solid;
    padding: 0.2em 0.75em 0.25em !important;
  }
}

.start-header {
  opacity: 1;
  transform: translateY(0);
  padding: 10px 0;
  box-shadow: 0 10px 30px 0 rgba(138, 155, 165, 0.15);
  transition: all 0.3s ease-out;
}
.start-header.scroll-on {
  box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);
  padding: 10px 0;
  transition: all 0.3s ease-out;
}
.start-header.scroll-on .navbar-brand img {
  height: 50px;
  transition: all 0.3s ease-out;
}
.start-header.scroll-on .nav-link,
.start-header.scroll-on .dap-tw-text {
  font-size: 17px;
  font-size: 1.0625rem;
}
.start-header .container {
  max-width: 1450px;
}

/* #Primary style
================================================== */
.bg-light {
  background-color: #fff !important;
  transition: all 200ms linear;
}

.nav-item .dropdown-menu {
  transform: translate3d(0, 10px, 0);
  visibility: hidden;
  opacity: 0;
  max-height: 0;
  display: none;
  padding: 0;
  margin: 0;
  transition: all 200ms linear;
}
.nav-item.show .dropdown-menu {
  display: block;
  opacity: 1;
  visibility: visible;
  max-height: 999px;
  transform: translate3d(0, 0px, 0);
}

.dropdown-menu {
  padding: 10px !important;
  margin: 0;
  font-size: 13px;
  letter-spacing: 1px;
  color: #212121;
  background-color: #fcfaff;
  border: none;
  border-radius: 3px;
  box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);
  transition: all 200ms linear;
}
.dropdown-toggle::after {
  display: none;
}
.dropdown-item {
  padding: 3px 15px;
  color: #212121;
  border-radius: 2px;
  transition: all 200ms linear;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #fff;
  background-color: #A86B4F;
}

.twitter-scroll {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.dap-tw-logo {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  height: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 1100px) {
  .dap-tw-logo {
    width: 22px;
  }
  .dap-tw-logo img {
    max-height: 22px;
  }
}
@media (max-width: 810px) {
  .dap-tw-logo {
    width: 6.25vw;
    background-color: transparent;
  }
  .dap-tw-logo img {
    max-height: 6.25vw;
  }
}
.dap-tw-item {
  padding: 0 1em;
  margin-left: 2rem;
  text-decoration: none;
  transition: background-color 0.25s ease;
  overflow: hidden;
}
.dap-tw-text {
  margin-right: 0.15em;
  color: #444343;
  font-weight: 500;
}
@media (min-width: 1200px) {
  .dap-tw-text {
    font-size: 1.3157894737vw;
  }
}
@media (min-width: 1500px) {
  .dap-tw-text {
    font-size: 1.0526315789vw;
  }
}
@media (max-width: 640px) {
  .dap-tw-text {
    font-size: 3.90625vw;
  }
}

/* #Media
================================================== */
@media (max-width: 810px) {
  .nav-item::after {
    display: none;
  }
  .nav-item::before {
    position: absolute;
    display: block;
    top: 20px;
    left: 0;
    width: 11px;
    height: 1px;
    content: "";
    border: none;
    background-color: #000;
  }
  .dropdown-toggle::after {
    position: absolute;
    display: block;
    top: 15px;
    left: -23px;
    width: 1px;
    height: 11px;
    content: "";
    border: none;
    background-color: #000;
    transition: all 200ms linear;
  }
  .dropdown-toggle[aria-expanded=true]::after {
    transform: rotate(90deg);
    opacity: 0;
  }
  .dropdown-toggle[aria-expanded=true] + .dropdown-menu {
    padding: 0 0 15px 0 !important;
    margin-top: 5px !important;
    margin-bottom: 20px !important;
  }
}
footer {
  background: #1b1b1b;
  color: #fff;
  padding: 50px 2rem 40px;
}
@media (max-width: 1080px) {
  footer {
    padding: 1rem 0.75rem;
  }
}
footer .wrapper {
  display: flex;
}
@media (min-width: 1001px) {
  footer .wrapper {
    max-width: 1400px;
  }
}
@media (max-width: 640px) {
  footer .wrapper {
    flex-direction: column;
  }
}

.divider {
  width: 2px;
  height: 100%;
  background: #bfb1a5;
}
@media (min-width: 800px) and (max-width: 1000px) {
  .divider {
    display: none;
  }
}

.footer-logo {
  display: flex;
  align-items: center;
}
.footer-logo img {
  display: block;
  max-width: 100%;
}

.footer {
  display: flex;
}
.footer-info .list .img {
  width: 50px;
  flex: none;
  margin-right: 10px;
}
@media (max-width: 1080px) {
  .footer-info .list .img {
    margin-right: 5px;
  }
}
.footer-info .list small {
  display: block;
  color: #383838;
  margin-bottom: 3px;
  margin-top: 7px;
}
.footer-info .list .time {
  padding-right: 10px;
}
.footer-info .list .txt {
  letter-spacing: 1px;
  line-height: 1.5;
}
.footer-info .list .txt p {
  margin-bottom: 5px;
  margin-left: 1rem;
  margin-right: 1rem;
}
.footer-info .list .txt:first-child {
  border-bottom: 1px solid #a89877;
  padding-left: 1rem;
  padding-right: 2rem;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}
@media (min-width: 1001px) {
  .footer-info .list .txt:first-child {
    padding-left: 2rem;
  }
}
@media (min-width: 1001px) and (max-width: 1250px) {
  .footer-info .list .txt:first-child {
    padding-left: 0;
    padding-right: 0;
    justify-content: space-between;
  }
}
@media (min-width: 1001px) {
  .footer-info .list .txt {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media (min-width: 1001px) {
  .footer-info {
    padding-left: 125px;
    padding-right: 125px;
  }
}
@media (min-width: 1001px) and (max-width: 1250px) {
  .footer-info {
    padding-left: 1rem;
    padding-right: 1rem;
    flex: 1;
  }
}
@media (max-width: 1080px) {
  .footer-info {
    width: 100%;
    margin-top: 15px;
    margin-bottom: 5px;
  }
  .footer-info .list {
    align-items: flex-start !important;
  }
  .footer-info .list .txt {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    padding-top: 0;
  }
  .footer-info .list .txt p {
    line-height: 1.5;
    margin-left: 0 !important;
    margin-bottom: 10px;
  }
  .footer-info .list .txt-big {
    font-size: 20px;
    font-size: 1.25rem;
  }
}
.footer-social p {
  margin-bottom: 0;
}
@media (max-width: 1024px) {
  .footer-social {
    position: absolute;
    bottom: 5px;
    right: 9px;
  }
}
.footer-social .social-icon {
  margin-bottom: 10px;
}
.footer.left, .footer.center, .footer.right {
  flex-grow: 1;
  max-width: 100%;
  display: inline-flex;
  align-items: flex-start;
}
.footer.left {
  align-items: center;
  justify-content: space-around;
}
@media (max-width: 810px) {
  .footer.left {
    flex-direction: column;
  }
}
@media (min-width: 1001px) {
  .footer.right {
    flex: none;
    padding-left: 60px;
  }
}
.footer.center .txt {
  line-height: 2.2;
}
.footer.center .txt p ~ p small {
  color: #10100f;
  font-size: 16px;
  font-size: 1rem;
}
@media (max-width: 1080px) {
  .footer.center .txt {
    line-height: 1.5;
  }
  .footer.center .txt p ~ p small span {
    display: block;
    margin-bottom: 5px;
  }
}
@media (min-width: 1025px) and (max-width: 1100px) {
  .footer.center {
    margin-left: 15px;
    margin-right: 5px;
  }
}
@media (min-width: 1001px) {
  .footer.left .list .txt p br {
    display: none;
  }
}

.iware {
  color: #fff;
  font-size: 13px;
  font-size: 73%;
  font-weight: 100;
  padding: 5px 8px 6px;
}
.iware a {
  color: #fff;
}
.iware a:hover {
  text-decoration: underline;
}

.float-link {
  right: -100%;
  position: fixed;
  bottom: 200px;
  z-index: 10;
  transition: 0.3s;
}
.float-link .link-btn a .circle {
  background: #A86B4F;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
  margin-bottom: 5px;
}
.float-link .link-btn a .circle::before {
  content: "";
  display: block;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  border-radius: 100%;
  position: absolute;
  z-index: 1;
  left: 5px;
  top: 5px;
}
.float-link .link-btn a .circle::after {
  content: "\f077";
  font-family: "FontAwesome";
  display: block;
  font-size: 25px;
  font-size: 1.5625rem;
  color: #fff;
}
.float-link .link-btn a .txt {
  display: block;
  color: #A86B4F;
  font-size: 17px;
  font-size: 1.0625rem;
  line-height: 1.2;
  text-align: center;
}
.float-link .link-btn a:hover {
  opacity: 0.65;
}
.float-link.active {
  right: 5px;
}
@media (max-width: 1600px) {
  .float-link .link-btn a span {
    font-size: 14px;
    font-size: 0.875rem;
  }
  .float-link.active {
    right: 10px;
  }
}
@media (max-width: 1080px) {
  .float-link {
    bottom: 10px;
  }
  .float-link .link-btn {
    flex: 1;
  }
  .float-link .link-btn br {
    display: none;
  }
  .float-link .link-btn a {
    width: 100%;
    height: 55px;
    border-radius: 0;
    margin: 0;
  }
  .float-link .link-btn a::before {
    display: none;
  }
  .float-link .link-btn a,
.float-link .link-btn a span {
    flex-direction: column !important;
  }
}

.cookies {
  display: none;
  width: 100%;
  position: fixed;
  background: white;
  border-top: 1px solid #aeadad;
  color: #333;
  font-size: 15px;
  font-size: 0.9375rem;
  font-size: 0.9375rem;
  text-align: center;
  bottom: 0;
  left: 0;
  z-index: 9999;
  padding: 0.5rem 1rem;
}
.cookies > p {
  font-weight: 400;
  line-height: 2;
}
.cookies > p a {
  text-decoration: underline !important;
  color: blue;
  font-weight: 500;
}
.cookies > p .btn {
  color: #fff;
  display: inline;
  font-size: 13px;
  font-size: 0.8125rem;
  text-decoration: none !important;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}
.cookies.display {
  display: block;
  animation: cookies 1s 1;
}
@media (max-width: 810px) {
  .cookies {
    width: calc(100% - 1em);
    margin: 0.5em;
    border-top: none;
    font-size: 14px;
    font-size: 0.875rem;
  }
}

.close-cookies {
  transition: all 0.2s ease-out;
  transform: translate3d(0, 100%, 0);
}

@keyframes cookies {
  0% {
    transform: translate3d(0, 100%, 0);
  }
  100% {
    transform: translate3d(0, 0%, 0);
  }
}
article {
  width: 100%;
  font-size: 100%;
}
article iframe {
  width: 100%;
  height: 750px;
}
@media (max-width: 810px) {
  article iframe {
    height: 350px;
  }
}

.normal-content {
  background-repeat: top;
  background-position: repeat-y;
  background-size: contain;
  background-image: url("../images/content_bg.jpg");
  overflow: hidden;
  padding: 2em 0;
  padding-top: 4%;
  margin-top: -4%;
  z-index: 0;
}
@media (min-width: 1101px) and (max-width: 1600px) {
  .normal-content .wrapper,
.normal-content .wrapper-1350.content-wrap {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}
@media (max-width: 1024px) {
  .normal-content .wrapper,
.normal-content .wrapper-1350.content-wrap {
    padding-left: 0.95rem;
    padding-right: 0.95rem;
  }
  .normal-content article {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

@media (min-width: 1001px) {
  .content-info {
    padding-top: 2rem;
    padding-right: 4.5rem;
  }
}
@media (max-width: 1000px) {
  .content-info .block-title-box {
    padding-left: 15px;
  }
}
.content-list {
  max-width: 100%;
  flex: 1;
}
@media (min-width: 1001px) {
  .content-list {
    margin-left: auto;
  }
}
@media (min-width: 1005px) and (max-width: 1200px) {
  .content-list {
    margin-left: 2rem;
  }
}
@media (max-width: 1024px) {
  .content {
    flex-direction: column;
  }
}
.content-wrap {
  padding-top: 45px;
  padding-bottom: 4em;
}
@media (max-width: 810px) {
  .content-wrap {
    padding-top: 1em;
    padding-bottom: 2em;
  }
}

.banner {
  margin-top: 5.6842105263vw;
}
@media (max-width: 810px) {
  .banner {
    margin-top: 28.125vw;
  }
}

.main-banner {
  z-index: 1;
}
.main-banner img {
  width: 100%;
}
@media (max-width: 640px) {
  .main-banner img {
    height: 200px;
    object-fit: cover;
    object-position: center;
  }
}

.text-main {
  color: #62563e !important;
}

.bg-second {
  background-color: #eeeeee !important;
}

.block-title-box {
  font-size: 45px;
  font-size: 2.8125rem;
  line-height: 1.25;
  margin-bottom: 1.25rem;
  z-index: 10;
}
.block-title-box p {
  margin-bottom: 0;
}
.block-title-box .txt {
  line-height: 1.8 !important;
  margin-top: 0.75rem;
}
.block-title-box .txt-big {
  font-size: 24px;
  font-size: 1.5rem;
  color: #010101;
}
.block-title-box .txt.en {
  color: #6d532d !important;
  font-size: 36px !important;
}
@media (min-width: 1001px) {
  .block-title-box .txt.en {
    padding-left: 2rem;
  }
}
.block-title-box .txt.ch {
  color: #2d456c;
  font-weight: 600;
  margin-top: 5px;
}
.block-title-box .txt.ch .font {
  color: #0160f8;
}
.block-title-box .txt.ch-small {
  color: #A86B4F;
  font-size: 18px;
  font-size: 1.125rem;
}
.block-title-box .txt.ch-small-gray {
  color: #939294;
  font-size: 22px;
  font-size: 1.375rem;
  font-weight: 100;
}
.block-title-box .txt.ch-medium {
  color: #374446;
  font-size: 24px;
  font-size: 1.5rem;
  font-weight: 100;
}
.block-title-box .img {
  margin-bottom: 2rem;
}
.block-title-box.main .txt.en {
  color: rgba(109, 83, 45, 0.62) !important;
}
.block-title-box.main .img {
  margin-top: 5px;
  margin-left: 10px;
}
.block-title-box.main .lineStyle-right {
  padding-left: 15px;
  color: #4f4132;
}
@media (max-width: 640px) {
  .block-title-box {
    margin-bottom: 0.25rem;
    font-size: 30px;
    font-size: 1.875rem;
  }
  .block-title-box .img {
    max-width: 10em;
    margin-bottom: 1em;
  }
  .block-title-box.main .img {
    margin-left: 0;
  }
  .block-title-box.main .img img {
    max-width: 70%;
  }
  .block-title-box.main .lineStyle-right {
    padding-left: 0;
  }
}

.blockTxt {
  font-size: 18px;
  line-height: 1.5;
  color: #8b8785;
  margin-top: 20px;
}

.lineStyle-right {
  font-size: 24px;
  letter-spacing: 1px;
  position: relative;
  display: flex;
  align-items: center;
}
.lineStyle-right p {
  flex: none;
  margin-bottom: 0;
  padding-right: 0.75rem;
}
.lineStyle-right p.subTxt {
  padding-right: 0;
}
.lineStyle-right::after {
  content: " ";
  display: block;
  border-bottom: 1px solid #000;
  width: 100%;
  max-width: 13rem;
}

.lineStyle-middleRight {
  position: relative;
  display: block;
  font-size: 24px;
  font-size: 1.5rem;
  padding: 0 55px 0 0;
  color: black;
}
.lineStyle-middleRight::after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  background-color: black;
  width: 45px;
  height: 3px;
  margin-left: 1rem;
  margin-top: -3px;
}

.divider {
  width: 2px;
  background: #6c6a6a;
}

.stickyBox {
  position: sticky;
  top: 0;
  left: 0;
}

@media (min-width: 1001px) {
  .block-pl85 {
    padding-left: 85px;
  }
  .block-pl45 {
    padding-left: 45px;
  }
  .block-pl35 {
    padding-left: 35px;
  }
}
.style__underLine {
  border-bottom-width: 4px;
  border-bottom-style: solid;
  border-bottom-color: #fff;
}
.style__underLine__small {
  border-top: none;
  border-bottom-width: 2px;
  border-bottom-style: dotted;
}

.tag-item {
  display: inline-block;
  width: auto;
  line-height: 1.1;
  margin: 2.5px;
  padding: 15px;
  text-align: center;
  color: white;
  background: #A86B4F;
  line-height: 1.2;
}
.tag-item.main {
  border: #a89877 1px solid;
  color: #a89877 !important;
  font-size: 17px !important;
  padding: 8px 12px;
}

.form-control {
  border: none;
  height: 50px;
}
.form-control, .form-control:focus {
  background-color: #eeeeee;
}

.form-control,
.btn {
  border-radius: 0;
}

.modal {
  text-align: center;
  padding-right: 0 !important;
}
.modal .modal-content {
  border-color: #accccd;
  border-width: 3px;
}

.modal-open {
  padding-right: 0 !important;
}

@media (min-width: 500px) {
  .modal-dialog {
    width: 100%;
    max-width: 85rem;
  }
}
@media (min-width: 1100px) and (max-width: 1500px) {
  .modal-dialog {
    max-width: 70rem;
  }
}
.modal-autoheight .modal-body {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 100px !important;
  max-height: 100vh !important;
}
@media (min-width: 500px) {
  .modal-autoheight .modal-body {
    max-height: calc(100vh - 50px) !important;
  }
}
@media (max-width: 1080px) {
  .modal-autoheight .modal-body {
    height: calc(90vh - 1.5rem) !important;
  }
}
@media (max-width: 1080px) {
  .modal-autoheight {
    transform: translateY(5%);
  }
}

@media (min-width: 1001px) {
  .modal {
    text-align: center;
    padding: 0 !important;
  }
  .modal:before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -4px;
  }
  .modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
  }
}
.checkbox_style > input[type=checkbox] {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  opacity: 0;
  cursor: pointer;
}
.checkbox_style > input + label {
  position: relative;
  display: flex;
  align-items: center;
  text-align: left;
  margin-top: 2px;
  margin-bottom: 5px;
  margin-right: 2px;
  margin-left: 0;
  padding-left: 1.75rem;
  cursor: pointer;
  border: none;
}
.checkbox_style > input + label::before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  font-size: 1.5rem;
  position: absolute;
  left: 0;
  top: 2px;
  border: #000 1px solid;
}
.checkbox_style > input + label::after {
  content: "\f00c";
  font-family: FontAwesome;
  position: absolute;
  left: 2px;
  font-size: 1rem;
  opacity: 0;
}
.checkbox_style > input:checked + label::after {
  opacity: 1;
}
.checkbox_style p {
  margin-top: 10px;
}

.radio_style {
  margin-top: 10px !important;
}
.radio_style input[type=radio] {
  display: none;
}
.radio_style input + label {
  position: relative;
  display: flex;
  align-items: center;
  line-height: 1;
  margin-bottom: 0;
}
.radio_style input + label::before, .radio_style input + label::after {
  content: "";
  display: inline-block;
  border-radius: 50%;
}
.radio_style input + label::before {
  width: 20px;
  height: 20px;
  border: #cecece 1px solid;
  background: #fff;
  vertical-align: middle;
  margin-right: 6px;
}
.radio_style input + label::after {
  width: 10px;
  height: 10px;
  background-color: transparent;
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translate(0%, -50%);
}
.radio_style input:checked + label::after {
  background-color: #2d456c;
}
@media (min-width: 1001px) {
  .radio_style ~ .radio_style {
    margin-left: 1rem;
  }
}

.btn-wrap {
  flex-wrap: nowrap;
  justify-content: center;
  width: 100%;
  margin: 2rem 0 1rem;
}
.btn-wrap > li {
  flex: 1;
}
.btn-wrap > li + li {
  margin-left: 1.5rem;
}
@media (max-width: 1080px) {
  .btn-wrap {
    margin-top: 1rem;
  }
  .btn-wrap > li + li {
    margin-left: 0.5rem;
  }
}

.btn {
  padding-top: 0.75em;
  padding-bottom: 0.75em;
  font-size: 17px;
  font-size: 1.0625rem;
}
.btn-border2w {
  border-width: 2px;
}

.card-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.card-wrap .card {
  width: auto;
  height: auto;
  margin: 2px 8px 10px;
  border: none;
  background: transparent;
  box-shadow: none;
  border-radius: 0 !important;
}
.card-wrap .card__list {
  position: relative;
  flex: 0 0 calc(33.3333333333% - 16px);
  max-width: calc(33.3333333333% - 16px);
}
.card-wrap .card p {
  position: relative;
  z-index: 3;
  margin: 0;
}
.card-wrap .card__body {
  padding: 0.5rem 0;
  line-height: 1.5;
}
.card-wrap .card-image-top {
  margin-bottom: 0.5rem;
}
.card-wrap .card__image {
  text-align: center;
  margin-bottom: 0.75rem;
}
.card-wrap .card__image img {
  object-fit: cover;
  object-position: center;
}
.card-wrap .card__title {
  font-size: 20px;
  font-size: 1.25rem;
  margin-bottom: 0.75rem;
  line-height: 1.5;
}
.card-wrap .card__description {
  padding: 8px 0;
  font-size: 17px;
  font-size: 1.0625rem;
  font-weight: 400;
  color: #332c2e;
}
.card-wrap.index-card-wrap {
  margin-top: 3rem;
}
.card-wrap.index-card-wrap .card__list {
  display: flex;
  flex-direction: column;
  padding: 1rem 1.25rem;
  background-color: rgba(40, 35, 31, 0.85);
}
@media (min-width: 1001px) {
  .card-wrap.index-card-wrap .card__list {
    justify-content: space-around;
    padding-top: 2rem;
    padding-left: 3.25rem;
    padding-right: 3.25rem;
    margin-left: 15px;
    margin-right: 15px;
    flex: 0 0 calc(25% - 30px);
    max-width: calc(25% - 30px);
  }
  .card-wrap.index-card-wrap .card__list:nth-child(odd) {
    border-bottom-right-radius: 4rem !important;
  }
  .card-wrap.index-card-wrap .card__list:nth-child(even) {
    border-top-left-radius: 4rem !important;
  }
}
@media (min-width: 1001px) and (max-width: 1380px) {
  .card-wrap.index-card-wrap .card__list {
    padding-left: 3rem;
    padding-right: 1rem;
  }
}
@media (max-width: 810px) {
  .card-wrap.index-card-wrap .card__list {
    padding-right: 1rem;
  }
}
.card-wrap.index-card-wrap .card__title {
  display: flex;
  color: #fff;
  font-weight: bold;
}
.card-wrap.index-card-wrap .card__title .en {
  text-transform: uppercase;
}
@media (min-width: 1001px) {
  .card-wrap.index-card-wrap .card__title {
    font-size: 1.8947368421vw;
    align-items: center;
  }
  .card-wrap.index-card-wrap .card__title .en {
    font-size: 1.2631578947vw;
  }
}
@media (max-width: 810px) {
  .card-wrap.index-card-wrap .card__title {
    flex-direction: column;
  }
}
.card-wrap.index-card-wrap .card__description {
  font-size: 18px;
  color: #9d9895;
  min-height: 11rem;
}
.card-wrap.index-card-wrap .card__image {
  text-align: left;
  margin-top: auto;
}
.card-wrap.index-card-wrap .card__image img {
  height: auto;
}
@media (max-width: 810px) {
  .card-wrap.index-card-wrap {
    margin-top: 1rem;
  }
}
.card-wrap.history-card-wrap {
  justify-content: flex-start;
  margin-top: 1rem;
}
.card-wrap.history-card-wrap .card__list {
  margin-bottom: 1rem;
}
@media (min-width: 1001px) {
  .card-wrap.history-card-wrap .card__list {
    margin-left: 30px;
    margin-right: 30px;
    flex: 0 0 calc(33.3333333333% - 60px);
    max-width: calc(33.3333333333% - 60px);
    margin-bottom: 3rem;
  }
}
.card-wrap.history-card-wrap .card__image {
  height: 0;
  padding-bottom: 75%;
  margin-bottom: 0;
}
.card-wrap.history-card-wrap .card__image img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-height: 100%;
  margin: auto;
}
.card-wrap.history-card-wrap .card__body {
  background-color: #fff;
  padding: 1rem 1.75rem 1.5rem;
}
@media (max-width: 640px) {
  .card-wrap.history-card-wrap .card__body {
    padding: 0.35em 1em 1.25em;
  }
}
.card-wrap.history-card-wrap .card__description {
  margin-bottom: 2px;
  padding-bottom: 0;
  color: #6f4538;
}
.card-wrap.history-card-wrap .card__title {
  font-size: 24px;
  font-size: 1.5rem;
  color: #020202;
}
@media (max-width: 810px) {
  .card-wrap.history-card-wrap .card__title {
    font-size: 20px;
    font-size: 1.25rem;
  }
}
.card-wrap.history-card-wrap .card__time {
  font-size: 16px;
  font-size: 1rem;
  color: #969595;
}
.card-wrap.history-card-wrap .card__btn {
  margin-top: 2.5em;
}
@media (max-width: 810px) {
  .card-wrap {
    justify-content: flex-start;
    margin: 1rem 0;
  }
  .card-wrap .card, .card-wrap .card__list {
    margin-left: 2px;
    margin-right: 2px;
    margin-bottom: 2px;
    max-width: calc(50% - 4px);
    flex: 0 0 calc(50% - 4px);
  }
  .card-wrap .card__image img {
    height: 167px;
  }
}
@media (max-width: 810px) {
  .card-wrap .card__title {
    font-size: 3.90625vw;
  }
  .card-wrap .card__caption {
    font-size: 3.125vw;
  }
}

.price {
  color: #62563e;
  font-size: 19px;
  font-size: 1.1875rem;
}

.card__note {
  text-align: center;
  color: #A86B4F;
  background-color: #aed5d7;
  border-radius: 1.0526315789vw 0px;
  padding: 8px 12px 9px;
  line-height: 1.3;
  font-family: "Noto Sans TC";
}
@media (max-width: 1024px) {
  .card__note {
    border-radius: 4.6875vw 0px;
  }
}

.card__price {
  width: 100%;
}
.card__price p {
  margin-bottom: 2px !important;
}
.card__price .price {
  padding-left: 10px;
  padding-right: 2px;
}
@media (max-width: 810px) {
  .card__price p {
    margin-bottom: 5px !important;
  }
  .card__price span.item {
    display: block;
  }
  .card__price span.item + .price {
    padding-left: 0;
  }
}

.card__infoList {
  display: flex;
  align-items: flex-start;
  margin-top: 2.5rem;
  margin-bottom: 0.5rem;
}
.card__infoList .card__note {
  min-width: 60px;
  flex: none;
}
@media (min-width: 640px) {
  .card__infoList .card__note + .card__price {
    margin-left: 1rem;
  }
}
@media (max-width: 810px) {
  .card__infoList .card__note {
    min-width: 100%;
    margin-bottom: 10px;
    padding-bottom: 8px;
  }
  .card__infoList .card__note br {
    display: none;
  }
}
@media (min-width: 900px) {
  .card__infoList {
    margin-bottom: 2rem;
  }
}
@media (max-width: 810px) {
  .card__infoList {
    flex-wrap: wrap;
  }
}

.image-slider {
  z-index: 2;
}
.image-slider .swiper-slide {
  position: relative;
  overflow: hidden;
}
@media (min-width: 800px) {
  .image-slider .swiper-slide .index-banner-wrapper {
    margin: 13.1578947368vw auto 13.1578947368vw;
  }
}
@media (max-width: 1100px) {
  .image-slider .swiper-slide {
    min-height: 53.8947368421vw;
  }
}
.image-slider .swiper-slide .img-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-bottom: 40%;
}
@media (max-width: 640px) {
  .image-slider .swiper-slide .img-container {
    position: static;
    height: 200px;
  }
}
.image-slider .content {
  width: 100%;
  margin: auto;
  flex-direction: row;
  justify-content: center;
}
@media (min-width: 1100px) {
  .image-slider .content {
    max-width: 84.2105263158vw;
  }
}
@media (max-width: 1080px) {
  .image-slider .content {
    max-width: 89.4736842105vw;
    margin-top: 3rem;
  }
}
@media (max-width: 640px) {
  .image-slider .content {
    max-width: 100%;
    flex-direction: column;
    background: #f0f4f3;
    padding: 1rem;
    margin-top: 0;
  }
}
.image-slider .swiper-pagination .swiper-pagination-bullet {
  width: 15px;
  height: 15px;
  opacity: 1;
  background-color: #fff;
  border: #192563 2px solid;
}
.image-slider .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #192563;
}
@media (min-width: 1100px) {
  .image-slider .swiper-pagination {
    max-width: 20rem;
    bottom: calc(15 * (1vw + 1vh - 1vmin)) !important;
    left: auto !important;
    right: 14.4736842105vw;
    text-align: left;
  }
}
@media (max-width: 1480px) {
  .image-slider .swiper-pagination {
    right: 15.2631578947vw;
  }
}
@media (min-width: 1025px) and (max-width: 1100px) {
  .image-slider .swiper-pagination {
    right: 7.3684210526vw;
  }
}
@media (max-width: 1024px) {
  .image-slider .swiper-pagination {
    bottom: 7.8125vw !important;
    max-width: 100%;
    margin: 0;
  }
}
@media (max-width: 810px) {
  .image-slider .swiper-pagination {
    bottom: 4.6875vw !important;
    max-width: 60%;
  }
}
.image-slider .text-wrapper {
  display: inline-flex;
}
.image-slider .text-wrapper .text-inner .sub-title,
.image-slider .text-wrapper .text-inner .title,
.image-slider .text-wrapper .text-inner .description {
  transform: translateY(50vw);
  transition: all ease 1s;
}
.image-slider .swiper-slide-active.active .text-inner .sub-title,
.image-slider .swiper-slide-active.active .text-inner .title,
.image-slider .swiper-slide-active.active .text-inner .description {
  transform: translateY(0);
  opacity: 1;
}
.image-slider .swiper-slide.active .text-inner .sub-title {
  transition-delay: 0.05s;
}
.image-slider .swiper-slide.active .text-inner .title {
  transition-delay: 0.1s;
}
.image-slider .swiper-slide.active .text-inner .description {
  transition-delay: 0.15s;
}

.index-content {
  padding: 120px 2rem;
}
@media (max-width: 1024px) {
  .index-content {
    padding: 1rem 1.2rem;
  }
}
.index-content .lineStyle-right {
  color: #fff;
}
.index-content .lineStyle-right::after {
  border-bottom-color: #fff;
}
.index-banner {
  margin-top: 5.6842105263vw;
}
.index-banner::after {
  content: "";
  display: block;
  width: 100%;
  min-height: 96px;
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  background-image: url("../images/banner_bottom.png");
  position: absolute;
  bottom: -28px;
  z-index: 2;
}
@media (max-width: 810px) {
  .index-banner {
    margin-bottom: 3rem;
  }
  .index-banner::after {
    bottom: -70px;
  }
}
@media (max-width: 810px) {
  .index-banner {
    margin-top: 13.28125vw;
    margin-bottom: 0;
  }
  .index-banner img {
    height: 200px;
    object-fit: cover;
    object-position: center;
  }
  .index-banner::after {
    display: none;
  }
}
@media (max-width: 640px) {
  .index-banner {
    margin-top: 28.125vw;
  }
}
.index-banner-title .ch *,
.index-banner-title .ch .font {
  color: black;
}
.index-banner-title .ch .font {
  font-size: 6.25vw;
  padding-left: 5px;
  padding-right: 5px;
}
@media (min-width: 800px) {
  .index-banner-title .ch {
    left: -5.5vw;
    font-size: 2.5263157895vw;
  }
  .index-banner-title .ch *,
.index-banner-title .ch .font {
    color: #fff;
  }
  .index-banner-title .ch .font {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (min-width: 1100px) {
  .index-banner-title .ch .font {
    font-size: 3.1578947368vw;
  }
}
@media (max-width: 1024px) {
  .index-banner-title .ch {
    font-size: 4.6875vw;
  }
}
.index-banner-title .txt {
  font-size: 18px;
  font-size: 1.125rem;
  color: #fff !important;
  background: rgba(0, 0, 0, 0.65);
  padding: 0.25rem 1rem;
  display: inline-block;
  text-align: center;
}
@media (min-width: 1100px) {
  .index-banner-title .txt {
    font-size: 2.1052631579vw !important;
    left: 1.75vw;
    padding-left: 3rem;
    padding-right: 3rem;
    margin-top: 1.5rem;
  }
}
.index-advantage {
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  background-image: url("../images/indexImg-advantage.jpg");
}
@media (min-width: 1001px) {
  .index-advantage {
    padding-bottom: 15rem;
  }
}
.index-advantage .lineStyle-right p {
  padding-right: 3rem;
}
.index-historyCase {
  display: flex;
  align-items: flex-start;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  background-image: url("../images/indexImg-historyCase.jpg");
}
@media (min-width: 1100px) {
  .index-historyCase {
    padding-left: 6rem;
    padding-right: 0;
    padding-bottom: 8rem;
  }
}
@media (max-width: 1380px) {
  .index-historyCase {
    padding-left: 1rem;
  }
}
@media (max-width: 810px) {
  .index-historyCase {
    flex-wrap: wrap;
  }
}
.index-historyCase .content {
  border-radius: 10px;
  overflow: hidden;
}
.index-historyCase .content .block-title-box .txt.en {
  padding-left: 0;
}
.index-historyCase .content .blockTxt {
  margin-bottom: 6.0526315789vw;
}
.index-historyCase .content .blockSwiper .blockTxt {
  margin-bottom: 0.5rem;
}
.index-historyCase .content.img {
  flex: 0 0 57.8947368421vw;
  max-width: 57.8947368421vw;
  z-index: 1;
  border-bottom-right-radius: 5em;
}
.index-historyCase .content.txt {
  flex-grow: 1;
  background-color: #1b1b1b;
  padding-left: 12.1052631579vw;
  padding-top: 7.8947368421vw;
  padding-bottom: 4.4736842105vw;
  padding-right: 3.6842105263vw;
  margin-left: -8.8421052632vw;
  margin-top: -13rem;
  z-index: 0;
}
@media (max-width: 810px) {
  .index-historyCase .content.img {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .index-historyCase .content.txt {
    margin-top: 1rem;
    margin-left: 0;
    padding: 0.5em 1.75em 1em;
  }
}
.index-historyCase .swiper-fun {
  width: 80%;
  display: flex;
  align-items: flex-end;
  z-index: 11;
}
.index-historyCase .swiper-arrow {
  display: flex;
  align-items: center;
  top: -1.5em;
}
.index-historyCase .swiper-button-prev, .index-historyCase .swiper-button-next {
  position: static;
  top: -5rem;
  margin-top: 0;
  color: #a89877;
}
.index-historyCase .swiper-button-prev::after, .index-historyCase .swiper-button-next::after {
  font-size: 1.85rem;
}
.index-historyCase .swiper-button-prev {
  margin-right: 0.75rem;
}
.index-historyCase .swiper-button-next {
  margin-left: 0.75rem;
}
.index-historyCase .swiper-pagination {
  position: static;
  display: flex;
  justify-content: flex-start;
}
.index-historyCase .swiper-pagination-bullet {
  border-radius: 0;
  flex-grow: 1;
  max-width: 6em;
  width: auto;
  height: 1px;
  background: #626262;
  opacity: 1;
}
.index-historyCase .swiper-pagination-bullet-active {
  background-color: #a89877;
}
@media (max-width: 810px) {
  .index-historyCase .swiper-pagination-bullet {
    flex: 1;
  }
}
.index-historyCase .diver {
  width: 1px;
  height: 20px;
  background: #626262;
  z-index: 2;
}
.index-historyCase .swiperCase-img.swiper-container {
  width: 100%;
  height: 100%;
}
.index-historyCase .swiperCase-img .swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 0;
  padding-bottom: 57%;
}
.index-historyCase .swiperCase-img .swiper-slide img {
  position: absolute;
  top: 0;
  transition-duration: 0.8s;
  transform: scale(1.2);
  opacity: 0;
}
.index-historyCase .swiperCase-img .swiper-slide-active img {
  opacity: 1;
  transform: none;
}
.index-historyCase .swiperCase-txt {
  width: 100%;
  overflow: hidden;
}
.index-historyCase .swiperCase-txt .swiper-wrapper {
  transition: 2s cubic-bezier(0.68, -0.4, 0.27, 1.34) 0.2s;
}
@media (max-width: 1380px) {
  .index-historyCase .swiper-fun {
    width: 100%;
  }
  .index-historyCase .swiper-arrow {
    margin-bottom: 1rem;
  }
}
.index-service {
  background-color: #010101;
  padding-top: 1rem;
}
@media (min-width: 1100px) {
  .index-service {
    padding-bottom: 9em;
  }
}
.index-service .conceptInner {
  position: relative;
  width: 100%;
  max-width: 1400px;
  display: flex;
  flex-wrap: nowrap;
  padding: 0 30px;
  margin: auto;
}
@media (min-width: 1100px) {
  .index-service .conceptInner .stickyBox {
    padding-top: 100px;
  }
}
@media (max-width: 1000px) {
  .index-service .conceptInner {
    flex-wrap: wrap;
    justify-content: space-around;
  }
}
@media (max-width: 640px) {
  .index-service .conceptInner {
    padding-left: 1em;
    padding-right: 1em;
  }
}
.index-service .text {
  width: 390px;
  position: relative;
  opacity: 1;
  transition: none;
  flex-shrink: 0;
}
@media (max-width: 1000px) {
  .index-service .text {
    width: 100%;
  }
}
.index-service .image {
  width: 950px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 1;
  margin-top: 230px;
}
.index-service .image li {
  width: 363px;
  margin-top: 217.8px;
  margin-left: 112px;
  position: relative;
}
.index-service .image li:nth-child(-n+2) {
  margin-top: 0;
}
.index-service .image li:nth-child(2n) {
  transform: translateY(290.4px);
}
.index-service .image li img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.index-service .image li .titleWrap {
  position: absolute;
  top: auto;
  left: auto;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1em 3.75em;
}
.index-service .image li .titleWrap::before {
  content: "";
  display: block;
  width: calc(100% - 4em);
  height: calc(100% - 4em);
  position: absolute;
  top: 2em;
  left: 2em;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 1em;
  z-index: 0;
  opacity: 0;
}
@media (min-width: 1101px) and (max-width: 1600px) {
  .index-service .image li .titleWrap {
    padding-left: 1em;
    padding-right: 1em;
  }
  .index-service .image li .titleWrap::before {
    width: calc(100% - 1em);
    height: calc(100% - 1em);
    top: 0.5em;
    left: 0.5em;
  }
}
.index-service .image li .num,
.index-service .image li .title,
.index-service .image li .txt {
  z-index: 1;
  transition: 0.5s;
}
.index-service .image li .num {
  font-family: "Roboto";
  font-size: 3.1578947368vw;
  color: rgba(255, 255, 255, 0.5);
  line-height: 1;
  margin-left: auto;
  bottom: 0.2em;
  right: 0.2em;
}
.index-service .image li .title {
  position: relative;
  width: auto;
  min-width: 60%;
  color: #fff;
  font-size: 18px;
  font-weight: normal;
  line-height: 2;
  text-align: center;
  white-space: nowrap;
  background: rgb(0, 0, 0);
  display: block;
  padding: 3px 3.5em;
}
.index-service .image li .txt {
  color: #4c4c4b;
  line-height: 1.5;
  margin-top: 1.5em;
  padding-left: 1.5em;
  padding-right: 1.5em;
  position: absolute;
  transform: translateY(1000%);
}
@media (min-width: 1101px) and (max-width: 1600px) {
  .index-service .image li .txt {
    padding-left: 1em;
    padding-right: 1em;
  }
}
.index-service .image li a {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 6px 0 24px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  overflow: hidden;
}
.index-service .image li a:hover .titleWrap::before, .index-service .image li a.active .titleWrap::before {
  opacity: 1;
}
.index-service .image li a:hover .num, .index-service .image li a.active .num {
  margin-left: 0;
  right: 0;
  bottom: 0.2rem;
  transform: translateX(0px);
  color: rgba(134, 121, 103, 0.8);
}
.index-service .image li a:hover .title, .index-service .image li a.active .title {
  background-color: transparent;
  color: #000;
}
.index-service .image li a:hover .title::after, .index-service .image li a.active .title::after {
  content: "";
  display: inline-block;
  width: 35px;
  height: 2px;
  background-color: #5a3616;
  position: absolute;
  bottom: -10px;
  left: 50%;
  margin-left: -17.5px;
}
.index-service .image li a:hover .txt, .index-service .image li a.active .txt {
  position: relative;
  transform: translateY(0);
}
@media (max-width: 1500px) {
  .index-service .image {
    width: calc(100% - 390px);
    display: flex;
    flex-wrap: wrap;
    padding-bottom: calc((42vw - 225px) * 0.8);
    margin-top: 200px;
  }
  .index-service .image li {
    position: relative;
    width: 40%;
    margin-top: calc((42vw - 225px) * 0.6);
    margin-left: 7.5%;
  }
  .index-service .image li:nth-child(2n) {
    transform: translateY(calc((42vw - 225px) * 0.8));
  }
}
@media (max-width: 810px) {
  .index-service .image {
    width: 100%;
    padding-left: 0;
    padding-bottom: 2em;
    margin-top: 0px;
    flex: 0 0 auto;
  }
  .index-service .image li {
    width: 100%;
    max-width: 360px;
    margin: 20px auto 0 !important;
    transform: translateY(0px) !important;
  }
  .index-service .image li .title {
    display: block;
    width: 100%;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
.index-service .blockTxt {
  color: #fff;
}
.index-service::after {
  content: "INTEGRITY PROFESSION";
  display: block;
  width: 100%;
  font-size: 8.4210526316vw;
  color: #44362a;
  line-height: 1;
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: center;
  white-space: nowrap;
  opacity: 0.5;
}

.banner-TopArrow-list {
  position: absolute;
  bottom: 1.5789473684vw;
  right: 14.4736842105vw;
  z-index: 3;
  width: 105px;
}
.banner-TopArrow-list .banner-TopArrow {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  animation: jump 1.5s infinite;
}
@media (max-width: 1024px) {
  .banner-TopArrow-list {
    display: none;
  }
}

@keyframes jump {
  0% {
    bottom: 0;
  }
  50% {
    bottom: 10px;
  }
  100% {
    bottom: 0;
  }
}
@keyframes moveCircle {
  0%, 100% {
    transform: translateY(-15%);
  }
  50% {
    transform: translateY(-5%);
  }
}
.about-intro .img {
  display: block;
  width: 100%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  background-image: url("../images/aboutImg_intro.png");
  height: 0;
  padding-bottom: 31%;
  margin-top: 2em;
}
@media (min-width: 1001px) {
  .about-intro .img {
    margin-top: 90px;
  }
}
@media (max-width: 1000px) {
  .about-intro .img {
    padding-bottom: 85%;
  }
}
.about-intro .txt {
  border: #bbbbbe 1px solid;
  padding: 0.5rem;
  font-size: 1em;
  font-family: "Noto Serif TC";
}
@media (min-width: 1001px) {
  .about-intro .txt {
    font-size: 30px;
    font-size: 1.875rem;
    line-height: 1.75;
    -webkit-writing-mode: vertical-rl;
    writing-mode: vertical-rl;
    padding: 45px 30px;
    position: absolute;
    right: 15.2631578947vw;
    top: -5.5263157895vw;
  }
}
@media (min-width: 1101px) and (max-width: 1600px) {
  .about-intro .txt {
    right: 13.1578947368vw;
  }
}
@media (max-width: 810px) {
  .about-intro .txt {
    text-align: center;
  }
  .about-intro .txt br {
    display: none;
  }
}
.about-caption .title {
  font-size: 18px;
  font-size: 1.125rem;
  color: #303030;
  line-height: 1.5;
  margin-bottom: 1em;
  padding-left: 1em;
  margin-left: 1em;
}
.about-caption .title::before {
  content: "";
  display: block;
  width: 1px;
  height: 100%;
  max-height: 210px;
  background-color: #303030;
  position: absolute;
  top: 0;
  left: 0;
}
@media (min-width: 1001px) {
  .about-caption .title {
    font-size: 22px;
    font-size: 1.375rem;
    line-height: 2.25;
    padding-top: 60px;
    padding-left: 70px;
    margin-top: -2.75em;
    margin-left: 8em;
    margin-bottom: 4em;
  }
  .about-caption .title::before {
    top: -30px;
  }
  .about-caption .title::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    max-width: 600px;
    max-height: 200px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    background-image: url("../images/contact-logo.png");
    position: absolute;
    top: 4em;
    right: -4em;
    mix-blend-mode: soft-light;
  }
}
@media (min-width: 1101px) and (max-width: 1600px) {
  .about-caption .title::after {
    right: 1em;
  }
}
.about-caption .item {
  background-color: #fff;
  border-radius: 1em;
  padding: 1rem;
  margin-top: 1em;
}
.about-caption .item .txt {
  font-size: 1.1em;
  line-height: 2.5;
}
@media (min-width: 1001px) {
  .about-caption .item {
    display: flex;
    padding-top: 120px;
    padding-bottom: 70px;
    padding-left: 80px;
    padding-right: 100px;
    margin-top: 8em;
  }
  .about-caption .item .img {
    flex: none;
    width: 640px;
    margin-right: 2em;
    margin-top: -11.5em;
  }
}

.history__content {
  flex-direction: column;
  background-color: #fff;
  padding: 1rem;
}
@media (min-width: 1001px) {
  .history__content {
    padding: 2rem 4rem;
  }
}
.history__title, .history__img {
  width: 100%;
}
.history__title {
  border-bottom: 2px solid #3b3b3c;
}
.history__title .title {
  font-size: 24px;
  font-size: 1.5rem;
  margin-bottom: 0.75rem;
}
@media (min-width: 1001px) {
  .history__title .title {
    font-size: 1.8947368421vw;
  }
}
.history__title .time {
  color: #969595;
}
.history__info {
  display: flex;
  margin-top: 45px;
  margin-bottom: 45px;
}
.history__info .title {
  color: #272727;
}
.history__info .item {
  color: #6f4538;
  font-size: 18px;
  font-size: 1.125rem;
}
@media (min-width: 1001px) {
  .history__info li + li {
    margin-left: 100px;
  }
}
@media (max-width: 810px) {
  .history__info {
    flex-direction: column;
    margin-top: 1em;
    margin-bottom: 1em;
  }
  .history__info li {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }
  .history__info .title {
    padding-right: 10px;
    flex: none;
    width: 70px;
  }
}
.history__img {
  margin-bottom: 1rem;
  overflow: hidden;
}
.history__img .swiper-slide {
  text-align: center;
}
.history__img .swiper-pagination-bullet {
  width: 15px;
  height: 15px;
  background-color: #fff;
  opacity: 1;
}
.history__img .swiper-pagination-bullet-active {
  background-color: transparent;
  border: #fff 2px solid;
}
@media (min-width: 1001px) {
  .history__img {
    margin-bottom: 4rem;
  }
}
@media (min-width: 1001px) {
  .history__item .lineStyle-middleRight {
    margin-bottom: 1rem;
  }
}
.history__item .txt {
  color: #505050;
  margin-bottom: 2rem;
  padding-left: 0;
}

.contact-bg {
  background-image: url("../images/bg-contact.jpg");
  padding-top: 120px;
  padding-bottom: 120px;
}

.contact-block {
  position: relative;
  display: flex;
}
@media (max-width: 1000px) {
  .contact-block {
    display: block;
  }
}
.contact-form {
  background: #fff;
  padding: 1rem;
  border: #bfbfbf 1px solid;
  border-bottom-right-radius: 4rem;
}
.contact-form legend {
  line-height: 1.5;
  font-size: 16px;
  font-size: 1rem;
  margin-bottom: 1rem;
}
.contact-form label {
  margin-bottom: 0;
}
.contact-form .form-control {
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: #a8a7a7;
  outline: none;
  box-shadow: none;
  background-color: #fff;
}
.contact-form .form-control:hover, .contact-form .form-control:focus {
  border-color: #000000;
}
.contact-form .form-group label {
  flex: none;
  width: 75px;
}
.contact-form .form-group .form-control {
  flex: 1;
}
.contact-form .btn-wrap li {
  display: flex;
  justify-content: center;
  width: 100%;
}
@media (max-width: 640px) {
  .contact-form .btn-wrap {
    flex-direction: column;
  }
  .contact-form .btn-wrap li {
    margin-top: 10px;
    margin-left: 0;
  }
  .contact-form .btn-wrap li .btn {
    margin-top: 0.5em;
  }
}
@media (min-width: 1001px) {
  .contact-form {
    padding: 2rem;
    width: 65%;
    margin-right: 130px;
  }
  .contact-form legend {
    margin-bottom: 2.75rem;
  }
}
@media (min-width: 1400px) {
  .contact-form {
    padding: 85px 130px;
  }
}
@media (max-width: 1024px) {
  .contact-form {
    padding-right: 2rem;
    margin-top: 2rem;
    max-width: 100%;
  }
}
.contact-info .title {
  font-size: 2.5263157895vw;
  margin-bottom: 17.5px;
}
.contact-info .title span {
  color: #62563e;
}
@media (max-width: 810px) {
  .contact-info .title {
    font-size: 7.5vw;
    margin-bottom: 0;
  }
}
.contact-info .txt {
  color: #242424;
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 2.25;
  width: 100%;
}
.contact-info .txt p {
  margin-bottom: 0;
}
@media (min-width: 1001px) {
  .contact-info .txt {
    width: 460px;
  }
}
@media (max-width: 1024px) {
  .contact-info .txt {
    font-size: 18px;
    font-size: 1.125rem;
    margin-top: 0;
  }
}
@media (min-width: 1001px) {
  .contact-info {
    width: 35%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 100px;
  }
  .contact-info::before {
    content: "";
    display: block;
    width: 600px;
    height: 200px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    background-image: url("../images/contact-logo.png");
    position: absolute;
    top: -7em;
    left: -15em;
    mix-blend-mode: soft-light;
  }
}
@media (max-width: 810px) {
  .contact-info {
    padding-top: 1em;
  }
}

.animationItem {
  transform: translate3d(-50px, 0, 0);
  opacity: 0;
  transition: 0.85s cubic-bezier(0.31, 1.26, 0.19, 1.11);
}
.animationItem.animated {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}