footer {
  background: $colorFooter;
  color:#fff;
  padding: 50px 2rem 40px;

  @include pad1080{
    padding: 1rem 0.75rem;
  }

  .wrapper {
      display: flex;
      @include setMinRwd{
        max-width: 1400px
      }

      @include mobile{
        flex-direction: column;
      }
  }
}

.divider {
  width: 2px;
  height: 100%;
  background: #bfb1a5;
  @include setWidthRwd(800px,1000px){
    display: none;
  }
}

.footer-logo {
  display: flex;
  align-items: center;
  img {
      display: block;
      max-width: 100%;
  }
}

.footer {
  display: flex;

  &-info{
    .list{
      .img{
        width: 50px;
        flex:none;
        margin-right: 10px;
        @include pad1080{
          margin-right: 5px;
        }
      }

      small{
        display: block;
        color:#383838;
        margin-bottom: 3px;
        margin-top: 7px;
      }

      .time{
        padding-right: 10px;  
      }

      .txt{
        letter-spacing: 1px;
        line-height: 1.5;
        p{
          margin-bottom: 5px;
          margin-left: 1rem;
          margin-right: 1rem;
        }
        &:first-child{
          border-bottom: 1px solid $colorSubs;
          padding-left: 1rem;
          padding-right: 2rem;
          padding-bottom: 1rem;
          margin-bottom: 1rem;
          @include setMinRwd{
            padding-left: 2rem;
          }
          @include setWidthRwd(1001px,1250px){
            padding-left: 0;
            padding-right: 0;
            justify-content: space-between;
          }
        }

        @include setMinRwd{
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
        }
      }
    }

    @include setMinRwd{
      padding-left: 125px;
      padding-right: 125px;
    }
    
    @include setWidthRwd(1001px,1250px){
      padding-left: 1rem;
      padding-right: 1rem;
      flex: 1;
    }

    @include pad1080{
      width: 100%;
      margin-top: 15px;
      margin-bottom: 5px;
      .list{
        align-items: flex-start !important;
        .txt{
          display: flex;
          flex-wrap: wrap;
          padding-left: 0;
          padding-top: 0;
          p{
            line-height: 1.5;
            margin-left: 0 !important;
            margin-bottom: 10px;
          }
          &-big{
            @include fontSize(20px)
          }
        }
      }
    }
  }
  
  &-social{
    p{
      margin-bottom: 0;
    }

    @include pad1024{
      position: absolute;
      bottom: 5px;
      right: 9px;
    }

    .social-icon{
      margin-bottom: 10px;
    }
  }

  &.left,
  &.center,
  &.right{
    flex-grow:1;
    max-width: 100%;
    display: inline-flex;
    align-items: flex-start;
  }

  &.left{
    align-items: center;
    justify-content: space-around;
    @include pad{
      flex-direction: column;
    }
  }

  &.right{
    @include setMinRwd{
      flex: none;;
      padding-left: 60px;
    }
  }

  &.center{
    .txt{
      line-height: 2.2;
      p{
        &~p{
          small{
            color:#10100f;
            @include fontSize(16px);
          }
        }
      }
      @include pad1080{
        line-height: 1.5;
        p{
          &~p{
            small{
              span{
                display: block;
                margin-bottom: 5px;
              }
            }
          }
        }
      }
    }
  }

  @include setWidthRwd(1025px,1100px){
    &.center{
      margin-left: 15px;
      margin-right: 5px;
    }
  }

  @include setMinRwd{
    &.left{
      .list{
        .txt p{
          br{
            display: none;
          }
        }
      }
    }
  }

}

.iware {
  color: #fff;
  font-size: 13px;
  font-size: 73%;
  font-weight: 100;
  padding: 5px 8px 6px;
  a {
      color: #fff;
      &:hover {
          text-decoration: underline;
      }
  }
}


.float-link {
  right: -100%;
  position: fixed;
  bottom: 200px;
  z-index: 10;
  transition: 0.3s;
  .link-btn {
      a {
        .circle{
          background: $colorMain;
          border-radius: 50%;
          width:50px;
          height:50px;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: 0.3s;
          margin-bottom: 5px;
          &::before{
            content: '';
            display: block;
            width: calc(100% - 10px);
            height: calc(100% - 10px);
            // border: rgba(255,255,255,0.5) 1px solid;
            border-radius: 100%;
            position: absolute;
            z-index: 1;
            left: 5px;
            top: 5px;
          }
          &::after {
              content: "\f077";
              font-family: 'FontAwesome';
              display: block;
              @include fontSize(25px);
              color: #fff;
          }
        }

        .txt{
          display: block;
          color: $colorMain;
          @include fontSize(17px);
          line-height: 1.2;
          text-align: center;
        }
        &:hover {
            opacity: 0.65;
        }
      }
  }

  &.active {
      right: 5px;
  }

  @include setStyleRwd(1600px){
    .link-btn {
      a {
        span{
          @include fontSize(14px);
        }
      }
    }

    &.active {
        right: 10px;
    }
  }

  @include pad1080{
    bottom: 10px;
    .link-btn {
      flex: 1;
      br{
        display: none;
      }
      a {
        width: 100%;
        height: 55px;
        border-radius: 0;
        margin: 0;
        &::before{
          display: none;
        }
         &,
         span{
          flex-direction: column !important;
         }
      }
    }
  }
}

// Cookie Privacy
.cookies {
  display: none;
  width: 100%;
  position: fixed;
  background: white;
  border-top: 1px solid #aeadad;
  color: #333;
  @include fontSize(15px);
  font-size: 0.9375rem;
  text-align: center;
  bottom: 0;
  left: 0;
  z-index: 9999;
  padding: .5rem 1rem;
  
  & > p {
    font-weight: 400;
    line-height: 2;
    a {
      text-decoration: underline !important;
      color: blue;
      font-weight: 500;
    }
    .btn {
      color:#fff;
      display: inline;
      @include fontSize(13px);
      text-decoration: none !important;
      padding-top: 0.4rem;
      padding-bottom: 0.4rem;
    }
  }
  
  &.display {
    display: block;
    animation: cookies 1s 1;
  }

  @include pad {
    width: calc(100% - 1em);
    margin: .5em;
    border-top: none;
    @include fontSize(14px);
  }
}

.close-cookies {
  transition: all 0.2s ease-out;
  transform: translate3d(0, 100%, 0);
}

@keyframes cookies {
  0% {
    transform: translate3d(0, 100%, 0);
  }
  100% {
    transform: translate3d(0, 0%, 0);
  }
}