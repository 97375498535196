article {
    width: 100%;
    font-size: 100%;
    iframe {
        width: 100%;
        height: 750px;
        @include pad {
            height: 350px;
        }
    }
}


// For 內頁區塊
.normal-content {
    @include setBack(top,repeat-y,contain);
    background-image: url('../images/content_bg.jpg');
    overflow: hidden;
    padding:2em 0;

    padding-top: 4%;
    margin-top: -4%;
    z-index: 0;
    @include setWidthRwd(1101px,1600px){
        .wrapper,
        .wrapper-1350.content-wrap{
            padding-left: 4rem;
            padding-right: 4rem;
        }
    }
    @include pad1024{
        .wrapper,
        .wrapper-1350.content-wrap{
            padding-left: 0.95rem;
            padding-right: 0.95rem;
        }

        article{
            padding-left: 0.5rem;
            padding-right: 0.5rem;
        }
    }
}

// 
.content{
    @extend %setFlex;
    &-info{
        @include setMinRwd{
            padding-top: 2rem;
            padding-right: 4.5rem;
        }

        @include setMaxRwd{
            .block-title-box{
                padding-left: 15px;
            }
        }
    }

    &-list{
        max-width: 100%;
        flex: 1;
        @include setMinRwd{
            margin-left: auto;
        }

        @include setWidthRwd(1005px,1200px){
            margin-left: 2rem;
        }
    }
    @include pad1024{
        flex-direction: column;
    }

    &-wrap{
        padding-top: 45px;
        padding-bottom: 4em;
        @include pad{
            padding-top: 1em;
            padding-bottom: 2em;
        }
    }
}

.banner{
    margin-top: get-vw(108px);
    
    @include pad{
        margin-top: get-vw-mobile(180px);
    }
}

// 內頁Banner
.main-banner{
    z-index: 1;
    img{
        width: 100%;
    }
    @include mobile{
        img{
            height: 200px;
            object-fit: cover;
            object-position: center;
        }
    }
}

// 文字 / 背景顏色
.text-main {
    color: $colorItem !important
}

.bg-second{
    background-color:$bgSecondary !important;
}

// 特用標題
.block-title-box{
    @include fontSize(45px);
    line-height: 1.25;
    margin-bottom: 1.25rem;
    z-index: 10;
    p{
        margin-bottom: 0;
    }

    .txt{
        line-height: 1.8 !important;
        margin-top: 0.75rem;
        &-big{
            @include fontSize(24px);
            color:#010101;
        }

        &.en{
            color:#6d532d !important;
            font-size: 36px !important;
            @extend %turbinado;
            @include setMinRwd{
                padding-left: 2rem;
            }
        }

        &.ch{
            color:$colorText;
            font-weight: 600;
            margin-top: 5px;
            .font{
                color:$colorSecondary
            }
            &-small{
                color:$colorMain;
                @include fontSize(18px);
            }
            &-small-gray{
                color:#939294;
                @include fontSize(22px);
                font-weight: 100;
            }
            &-medium{
                color:#374446;
                @include fontSize(24px);
                font-weight: 100;
            }
        }
    }

    .img{
        margin-bottom: 2rem;
    }

    &.main{
        .txt{
            &.en{
                color:rgba(109, 83, 45,0.62) !important
            }
        }
        
        .img{
            margin-top: 5px;
            margin-left: 10px;
        }

        .lineStyle-right{
            padding-left: 15px;
            color:#4f4132;
        }
    }
    
    @include mobile{
        margin-bottom: 0.25rem;
        @include fontSize(30px);
        .img{
            max-width: 10em;
            margin-bottom: 1em;
        }
        
        &.main{
            .img{
                margin-left: 0;
                img{
                    max-width: 70%;
                }
            }

            .lineStyle-right {
                padding-left: 0;
            }
        }
    }
}

.blockTxt{
    font-size: 18px;
    line-height: 1.5;
    color:#8b8785;
    margin-top: 20px;
}

// 標題樣式
.lineStyle-right{
    font-size: 24px;
    letter-spacing: 1px;
    position: relative;
    display: flex;
    align-items: center;
    p{
        flex: none;
        margin-bottom: 0;
        padding-right: 0.75rem;
        &.subTxt{
            padding-right: 0;
        }
    }
    &::after{
        content: " ";
        display: block;
        border-bottom: 1px solid #000;
        width: 100%;
        max-width: 13rem;
    }
}

.lineStyle-middleRight{
    position: relative;
    display: block;
    @include fontSize(24px);
    padding: 0 55px 0 0;
    color: black;
    &::after {
        content: '';
        display: inline-block;
        vertical-align: middle;
        background-color: black;
        width: 45px;
        height: 3px;
        margin-left: 1rem;
        margin-top: -3px;
    }
}

// 分隔線
.divider {
    width: 2px;
    background: #6c6a6a;
}

// 吸頂Sticky
.stickyBox {
    position: sticky;
    top: 0;
    left: 0;
}

// 邊距
@include setMinRwd{
    .block-pl85{
        padding-left: 85px;
    }
    
    .block-pl45{
        padding-left: 45px;
    }
    
    .block-pl35{
        padding-left: 35px;
    }
}

// 底線樣式
.style__underLine{
    border-bottom-width: 4px;
    border-bottom-style: solid;
    border-bottom-color: #fff;
    &__small{
        border-top: none;
        border-bottom-width:2px;
        border-bottom-style: dotted;
    }
}

// 項目
.tag-item {
    display: inline-block;
    width: auto;
    line-height: 1.1;
    margin: 2.5px;
    padding: 15px;
    text-align: center;
    color: white;
    background: $colorMain;
    line-height: 1.2;
    &.main {
        border: $colorSubs 1px solid;
        color: $colorSubs !important;
        font-size: 17px !important;
        padding: 8px 12px;
    }
}

.form-control{
    border:none;
    height: 50px;
    &,
    &:focus{
        background-color: #eeeeee;
    }
}

.form-control,
.btn{
    border-radius: 0;
}

.modal {
    text-align: center;
    padding-right: 0 !important;
    .modal-content{
        border-color: #accccd;
        border-width: 3px;
    }
}

.modal-open{
    padding-right: 0 !important;
}

@include setStyleMinRwd(500px){
    .modal-dialog {
        width: 100%;
        max-width: 85rem;
    }
}

@include setWidthRwd(1100px,1500px){
    .modal-dialog {
        max-width: 70rem;
    }
}
    
.modal-autoheight{
    .modal-body {
        position: relative;
        overflow-y: auto;
        overflow-x: hidden;
        min-height: 100px !important;
        max-height: 100vh !important;
        @include setStyleMinRwd(500px){
            max-height: calc(100vh - 50px) !important
        }
        @include pad1080{
            height: calc(90vh - 1.5rem) !important;
        }
    }
    @include pad1080{
        transform: translateY(5%);
    }
}

@include setMinRwd{
    .modal {
        text-align: center;
        padding: 0!important;
        &:before {
            content: '';
            display: inline-block;
            height: 100%;
            vertical-align: middle;
            margin-right: -4px;
        }
        &-dialog {
            display: inline-block;
            text-align: left;
            vertical-align: middle;
        }
    }
}


// Checkbox & radio
.checkbox_style {
    &>input {
        &[type="checkbox"] {
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 1;
            opacity: 0;
            cursor: pointer;
        }
        &+label {
            position: relative;
            display: flex;
            align-items: center;
            text-align: left;
            margin-top: 2px;
            margin-bottom: 5px;
            margin-right: 2px;
            margin-left: 0;
            padding-left: 1.75rem;
            cursor: pointer;
            border: none;
            &::before {
                content: "";
                display: inline-block;
                width: 20px;
                height: 20px;
                font-size: 1.5rem;
                position: absolute;
                left: 0;
                top: 2px;
                border: #000 1px solid;
            }
            &::after {
                content: "\f00c";
                font-family: FontAwesome;
                position: absolute;
                left: 2px;
                font-size: 1rem;
                opacity: 0;
            }
        }
        &:checked+label::after {
            opacity: 1;
        }
    }
    p {
        margin-top: 10px;
    }
}

.radio_style {
    @extend %setFlex-center;
    margin-top: 10px !important;
    input {
        &[type="radio"] {
            display: none;
        }
        &+label {
            position: relative;
            display: flex;
            align-items: center;
            line-height: 1;
            margin-bottom: 0;
            &::before,
            &::after {
                content: "";
                display: inline-block;
                border-radius: 50%;
            }
            &::before {
                width: 20px;
                height: 20px;
                border: #cecece 1px solid;
                background: #fff;
                vertical-align: middle;
                margin-right: 6px;
            }
            &::after {
                width: 10px;
                height: 10px;
                background-color: transparent;
                position: absolute;
                left: 5px;
                top: 50%;
                transform: translate(0%, -50%);
            }
        }
        &:checked {
            &+label {
                &::after {
                    background-color: $colorText;
                }
            }
        }
    }
    @include setMinRwd {
        &~.radio_style {
            margin-left: 1rem;
        }
    }
}

// 按鈕
.btn-wrap {
    @extend %setFlex-center;
    flex-wrap: nowrap;
    justify-content: center;
    width: 100%;
    margin: 2rem 0 1rem;
    &>li {
        flex: 1;
        &+li {
            margin-left: 1.5rem;
        }
    }
    @include pad1080 {
        margin-top: 1rem;
        &>li {
            &+li {
                margin-left: 0.5rem;
            }
        }
    }
}

.btn{
    padding-top: 0.75em;
    padding-bottom: 0.75em;
    @include fontSize(17px);
    &-border2w{
        border-width: 2px;
    }
}