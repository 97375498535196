.card-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  .card {
    width: auto;
    height: auto;
    margin: 2px 8px 10px;
    border:none;
    background:transparent;
    box-shadow:none;
    border-radius: 0 !important;
    &__list{
      position: relative;
      flex: 0 0 calc((100% / 3) - 16px);
      max-width: calc((100% / 3) - 16px);
    }
    p{
      position: relative;
      z-index: 3;
      margin: 0;
    }

    &__body {
      padding: .5rem 0;
      line-height: 1.5;
    }

    &-image-top{
      margin-bottom: 0.5rem;
    }

    &__image {
      text-align: center;
      margin-bottom: 0.75rem;
      img {
        object-fit: cover;
        object-position: center;
      }
    }

    &__title {
      @include fontSize(20px);
      margin-bottom: 0.75rem;
      line-height: 1.5;
    }

    &__description {
      padding: 8px 0;
      @include fontSize(17px);
      font-weight:400;
      color:#332c2e;
    }
  }

  &.index-card-wrap{
    margin-top: 3rem;
    .card {
      &__list{
        display: flex;
        flex-direction: column;
        padding:1rem 1.25rem;
        background-color: rgba(40, 35, 31,0.85);
        @include setMinRwd{
          justify-content: space-around;

          padding-top: 2rem;
          padding-left: 3.25rem;
          padding-right: 3.25rem;
          margin-left: 15px;
          margin-right: 15px;
          flex: 0 0 calc((100% / 4) - 30px);
          max-width: calc((100% / 4) - 30px);

          &:nth-child(odd){
            border-bottom-right-radius: 4rem !important;
          }
          &:nth-child(even){
            border-top-left-radius: 4rem !important;
          }
        }

        @include setWidthRwd(1001px,1380px){
          padding-left: 3rem;
          padding-right: 1rem;
        }
        @include pad{
          padding-right: 1rem;
        }
      }

      &__title {
        display: flex;
        color: #fff;
        font-weight: bold;
        .en{
          text-transform: uppercase;
        }
        
        @include setMinRwd{
          font-size: get-vw(36px);
          align-items: center;
          .en{
            font-size: get-vw(24px);
          }
        }

        @include pad{
          flex-direction: column;
        }
      }

      &__description{
        font-size: 18px;
        color:#9d9895;
        min-height: 11rem;
      }

      &__image {
        text-align: left;
        margin-top: auto;
        img{
          height: auto;
        }
      }
    }

    @include pad{
      margin-top: 1rem;
    }
  }

  &.history-card-wrap{
    justify-content: flex-start;
    margin-top: 1rem;
    .card{
      &__list{
        margin-bottom: 1rem;
        @include setMinRwd{
          margin-left: 30px;
          margin-right: 30px;
          flex: 0 0 calc((100% / 3) - 60px);
          max-width: calc((100% / 3) - 60px);
          margin-bottom: 3rem;
        }
      }
      &__image{
        height: 0;
        padding-bottom: 75%;
        margin-bottom: 0;
        img{
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          max-height: 100%;
          margin: auto;
        }
      }

      &__body{
        background-color: #fff;
        padding: 1rem 1.75rem 1.5rem;
        @include mobile{
          padding: 0.35em 1em 1.25em;
        }
      }

      &__description{
        margin-bottom: 2px;
        padding-bottom: 0;
        color:#6f4538;
      }

      &__title{
        @include fontSize(24px);
        color:#020202;
        @include pad{
          @include fontSize(20px)
        }
      }

      &__time{
        @include fontSize(16px);
        color:#969595;
      }

      &__btn{
        margin-top: 2.5em;
      }
    }
  }
  

  @include pad{
    justify-content: flex-start;
    margin:1rem 0;
    .card{
      &,
      &__list{
        margin-left: 2px;
        margin-right: 2px;
        margin-bottom: 2px;
        max-width: calc((100% / 2) - 4px);
        flex:0 0 calc((100% / 2) - 4px);
      }

      &__image {
        img {
          height: 167px;
        }
      }
    }
  }

  @include pad{
    .card{
      &__title {
        font-size: get-vw-mobile(25px);
      }
      &__caption {
        font-size: get-vw-mobile(20px);
      }
    }
  }
}

// 另類樣式
.price{
  color:$colorItem;
  @include fontSize(19px)
}

.card__note{
  text-align: center;
  color:$colorMain;
  background-color: #aed5d7;
  border-radius: get-vw(20px) 0px;
  padding: 8px 12px 9px;
  line-height: 1.3;
  font-family: $fontNotosanc-tc;
  @include pad1024{
    border-radius: get-vw-mobile(30px) 0px;
  }
}

.card__price{
  width: 100%;
  p{
    margin-bottom: 2px !important;
  }

  .price{
    padding-left: 10px;
    padding-right: 2px;
  }

  @include pad{
    p{
      margin-bottom: 5px !important;
    }
    span.item{
      display: block;
      &+.price{
        padding-left: 0;
      }
    }
  }

}

.card__infoList{
  display: flex;
  align-items:flex-start;
  margin-top: 2.5rem;
  margin-bottom:0.5rem;
  .card__note{
    min-width: 60px;
    flex:none;
    @include setStyleMinRwd(640px){
      &+.card__price{
        margin-left: 1rem;
      }
    }
    @include pad{
      min-width: 100%;
      margin-bottom: 10px;
      padding-bottom: 8px;
      br{
        display: none;
      }
    }
  }
  
  @include setStyleMinRwd(900px){
    margin-bottom: 2rem;
  }

  @include pad{
    flex-wrap: wrap;
  }
}
  