// 聯絡我們
.contact-bg{
    @extend %setBack;
    background-image: url('../images/bg-contact.jpg');
    padding-top: 120px;
    padding-bottom: 120px;
}


.contact{
    &-block {
        position: relative;
        display: flex;

        @include setMaxRwd{
            display: block;
        }
    }

    &-form {
        background: #fff;
        padding: 1rem;
        border:#bfbfbf 1px solid;
        border-bottom-right-radius: 4rem;
        legend{
            line-height:1.5;
            @include fontSize(16px);
            margin-bottom: 1rem;
        }

        label{
            margin-bottom: 0;
        }

        .form-control{
            &{
                border-width:0 0 1px 0;
                border-style: solid;
                border-color: #a8a7a7;
                outline: none;
                box-shadow: none;
                background-color: #fff;
            }
            &:hover,
            &:focus{
                border-color: #000000
            }
        }

        .form-group{
            @extend %setFlex-center;
            label{
                flex: none;
                width: 75px;
            }
            .form-control{
                flex: 1;
            }
        }

        .btn-wrap{
            li{
                display: flex;
                justify-content: center;
                width:100%;
            }
            @include mobile{
                flex-direction: column;
                li{
                    margin-top: 10px;
                    margin-left: 0;
                    .btn{
                        margin-top: 0.5em;
                    }
                }
            }
        }

        @include setMinRwd{
            padding: 2rem;
            width: 65%;
            margin-right: 130px;
            legend{
                margin-bottom: 2.75rem;
            }
        }

        @include setStyleMinRwd(1400px){
            padding: 85px 130px;
        }

        @include pad1024{
            padding-right: 2rem;
            margin-top: 2rem;
            max-width: 100%;
        }
    }

    &-info{
        .title{
            font-size: get-vw(48px);
            margin-bottom: 17.5px;
            span{
                color:$colorItem
            }

            @include pad{
                font-size: get-vw-mobile(48px);
                margin-bottom: 0;
            }
        }
        
        .txt{
            color:#242424;
            @include fontSize(20px);
            font-weight: 500;
            line-height: 2.25;
            width: 100%;
            p{
                margin-bottom: 0;
            }

            @include setMinRwd{
               width: 460px;
            }

            @include pad1024{
                @include fontSize(18px);
                margin-top: 0;
            }
        }

        @include setMinRwd{
            width: 35%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding-bottom: 100px;
            &::before{
                content: '';
                display: block;
                width: 600px;
                height: 200px;
                @include setBack;
                background-image: url('../images/contact-logo.png');
                position: absolute;
                top: -7em;
                left: -15em;
                mix-blend-mode: soft-light;
            }
        }

        @include pad{
            padding-top: 1em;
        }
    }
}