.about{
  &-intro{
    .img{
      display: block;
      width: 100%;
      @include setBack;
      background-image: url('../images/aboutImg_intro.png');
      height: 0;
      padding-bottom: 31%;
      margin-top: 2em;

      @include setMinRwd{
        margin-top: 90px;
      }
      
      @include setMaxRwd{
        padding-bottom: 85%;
      }
    }

    .txt{
      border:#bbbbbe 1px solid;
      padding: 0.5rem;
      font-size: 1em;
      font-family: $fontNotoserif-tc;

      @include setMinRwd{
        @include fontSize(30px);
        line-height: 1.75;
        -webkit-writing-mode: vertical-rl;
        writing-mode: vertical-rl;
        padding: 45px 30px;

        position: absolute;
        right: get-vw(290px);
        top: get-vw(-105px);
      }

      @include setWidthRwd(1101px,1600px){
        right: get-vw(250px);
      }

      @include pad{
        text-align: center;
        br{
          display: none;
        }
      }
    }
  }

  &-caption{
    .title{
      @include fontSize(18px);
      color:#303030;
      line-height: 1.5;

      margin-bottom: 1em;
      padding-left: 1em;
      margin-left: 1em;

      &::before{
        content: '';
        display: block;
        width: 1px;
        height: 100%;
        max-height: 210px;
        background-color: #303030;
        position: absolute;
        top: 0;
        left: 0;
      }

      @include setMinRwd{
        @include fontSize(22px);
        line-height: 2.25;
        padding-top: 60px;
        padding-left: 70px;
        margin-top: -2.75em;
        margin-left: 8em;
        margin-bottom: 4em;

        &::before{
          top: -30px;
        }

        &::after{
          content: '';
          display: block;
          @include setSize(100%,100%);
          max-width: 600px;
          max-height: 200px;
          @include setBack;
          background-image: url('../images/contact-logo.png');
          position: absolute;
          top: 4em;
          right: -4em;
          mix-blend-mode: soft-light;
        }
      }

      @include setWidthRwd(1101px,1600px){
        &::after{
          right: 1em;
        }
      }
    }

    .item{
      background-color: #fff;
      border-radius: 1em;
      padding: 1rem;
      margin-top: 1em;

      .txt{
        font-size: 1.1em;
        line-height: 2.5;
      }

      @include setMinRwd{
        display: flex;
        padding-top: 120px;
        padding-bottom: 70px;
        padding-left: 80px;
        padding-right: 100px;
        margin-top: 8em;
        .img{
          flex: none;
          width: 640px;
          margin-right: 2em;
          margin-top: -11.5em;
        }
      }
    }
  }
}