.history{
    &__content{
        flex-direction: column;
        background-color: #fff;
        padding: 1rem;
    
        @include setMinRwd{
            padding: 2rem 4rem;
        }
    }

    &__title,
    &__img{
        width: 100%;
    }

    &__title{
        border-bottom: 2px solid #3b3b3c;
        .title{
            @include fontSize(24px);
            margin-bottom: 0.75rem;
    
            @include setMinRwd{
                font-size: get-vw(36px);
            }
        }
        .time{
            color: #969595;
        }
    }

    &__info{
        display: flex;
        margin-top: 45px;
        margin-bottom: 45px;
    
        .title{
            color:#272727;
        }
        .item{
            color:#6f4538;
            @include fontSize(18px);
        }

        @include setMinRwd{
            li{
                &+li{
                    margin-left: 100px;
                }
            }
        }

        @include pad{
            flex-direction: column;
            margin-top: 1em;
            margin-bottom: 1em;
            li{
                display: flex;
                align-items: center;
                margin-bottom: 5px;
            }

            .title{
                padding-right: 10px;
                flex: none;
                width: 70px;
            }
        }
    }

    &__img{
        margin-bottom: 1rem;
        overflow: hidden;
        
        .swiper-slide{
            text-align: center;
        }
    
        .swiper-pagination-bullet {
            width: 15px;
            height: 15px;
            background-color: #fff;
            opacity: 1;
            &-active{
                background-color: transparent;
                border:#fff 2px solid;
            }
        }

        @include setMinRwd{
            margin-bottom: 4rem;
        }
    }

    &__item{
        .lineStyle-middleRight{
            @include setMinRwd{
                margin-bottom: 1rem;
            }
        }
    
        .txt{
            color:#505050;
            margin-bottom: 2rem;
            padding-left: 0;
        }
    }
}

