/* #Navigation
================================================== */

.navigation-wrap{
	position: fixed;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 1050;
	-webkit-transition : all 0.3s ease-out;
	transition : all 0.3s ease-out;
}

.navbar{
	padding: 0;

    // LOGO
    &-brand{
        margin-right: 0;
    }

    // 
    &-light{
        .navbar-toggler-icon {
            width: 24px;
            height: 17px;
            background-image: none;
            position: relative;
            border-bottom: 1px solid #000;
            transition: all 300ms linear;
            &::after, 
            &::before{
                width: 24px;
                position: absolute;
                height: 1px;
                background-color: #000;
                top: 0;
                left: 0;
                content: '';
                z-index: 2;
                transition: all 300ms linear;
            }
            &::after{
                top: 8px;
            }
        }
    }

    // 手機用的漢堡
    &-toggler {
        position: absolute;
        right: 0;
        top: 2rem;
        border: none;
        padding-right: 0;
        &:active,
        &:focus {
            outline: none;
        }
        &[aria-expanded="true"] .navbar-toggler-icon:after {
            transform: rotate(45deg);
        }
        &[aria-expanded="true"] .navbar-toggler-icon:before {
            transform: translateY(8px) rotate(-45deg);
        }
        &[aria-expanded="true"] .navbar-toggler-icon {
            border-color: transparent;
        }
    }

    @include setMinRwd{
        justify-content: space-between;

        // gird
        &-brand,
        &-link,
        &-marquee{
            flex: auto;
        }

        &-link{
            order:-1
        }

        // 跑馬燈
        &-marquee{
            flex: 0 0 calc(250px + 1rem);
            max-width: calc(250px + 1rem);
            padding-left: 1rem;
            padding-right: 1rem;
            
            color: #444343;
            border-right: $colorMain 1px solid;
            border-left: $colorMain 1px solid;
        }
    }

    @include setStyleMinRwd(1100px){
        // 跑馬燈
        &-marquee{
            flex: 0 0 calc(400px + 1rem);
            max-width: calc(400px + 1rem);
        }
    }

    @include setStyleMinRwd(1500px){
        // 跑馬燈
        &-marquee{
            font-size: get-vw(20px);
        }
    }

    @include pad{
        flex-direction: column;
        align-items: flex-start;

        &-link{
            order:2
        }

        &-marquee{
            width: 100%;
            padding: 5px 10px;
        }
    }

    @include setWidthRwd(800px,1025px){
        &-link{
            @include fontSize(18px)
        }
    }
    
    @include setWidthRwd(750px,1000px){
        &-link{
            position: absolute;
            right: 1em;
            top: 2.5em;
        }
        &-marquee {
            position: absolute;
            right: 1em;
            top: 0em;
            max-width: 20em;
        }
    }

    @include mobile{
        &-link{
            &,
            .navbar-nav{
                width: 100%;
            }
            .navbar-nav{
                flex-direction: row;
                justify-content: space-between;
            }
        }
    }

}

// 
.nav{
    &-link{
        position: relative;
        padding: 5px 0 !important;
        display: inline-block;
        color: #212121 !important;
        font-weight: 500;
        transition: all 200ms linear;
        @include setStyleMinRwd(1200px){
            font-size: get-vw(28px);
        }
        @include setStyleMinRwd(1500px){
            font-size: get-vw(20px);
        }
    }

    &-item{
        position: relative;
        transition: all 200ms linear;
        padding-right: 1.8rem;
        padding-left: 1.8rem;

        &.active,
        &:hover{
            .nav-link{
                color: $colorMain !important;
            }
        }
    
        // 語言
        &-lang{
            &::after{
                display: none;
            }
        }

        @include setMinRwd{
            border-left: $colorMain 1px solid;
        }

        @include setStyleRwd(1025px){
            padding-right: 1rem;
            padding-left: 1rem;
        }
    }

    // 語言的style
    &-lang{
        display: flex;
        align-items: center;
        color: $colorMain;
        line-height: 27px;
        font-size: 16px;
        .icon {
            display: inline-block;
            flex: none;
            margin-right: 0.45rem;
            margin-top: -2px;
        }
        @include setMinRwd{
            border-radius: 20px;
            border: $colorMain 1px solid;
            padding: 0.2em 0.75em 0.25em !important;
        }
    }
}

// 
.start-header {
	opacity: 1;
	transform: translateY(0);
	padding: 10px 0;
	box-shadow: 0 10px 30px 0 rgba(138, 155, 165, 0.15);
	transition : all 0.3s ease-out;
    &.scroll-on {
        box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);
        padding: 10px 0;
        transition : all 0.3s ease-out;
        .navbar-brand{
            img{
                height: 50px;
                transition : all 0.3s ease-out;
            }
        }
            
        .nav-link,
        .dap-tw-text{
            @include fontSize(17px)
        }
    }
    .container{
        max-width: 1450px;
    }
}

/* #Primary style
================================================== */

.bg-light {
	background-color: #fff !important;
    transition: all 200ms linear;
}

.nav-item{
    .dropdown-menu {
        transform: translate3d(0, 10px, 0);
        visibility: hidden;
        opacity: 0;
        max-height: 0;
        display: none;
        padding: 0;
        margin: 0;
        transition: all 200ms linear;
    }
    &.show{
        .dropdown-menu {
            display: block;
            opacity: 1;
            visibility: visible;
            max-height: 999px;
            transform: translate3d(0, 0px, 0);
        }
    }
}

.dropdown{
    &-menu {
        padding: 10px!important;
        margin: 0;
        font-size: 13px;
        letter-spacing: 1px;
        color: #212121;
        background-color: #fcfaff;
        border: none;
        border-radius: 3px;
        box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);
        transition: all 200ms linear;
    }

    &-toggle{
        &::after {
            display: none;
        }
    }
    &-item {
        padding: 3px 15px;
        color: #212121;
        border-radius: 2px;
        transition: all 200ms linear;
        &:hover, 
        &:focus {
            color: #fff;
            background-color: $colorMain;
        }
    }
}

.twitter-scroll {
    display: flex;
    align-items: center;
    overflow: hidden;
}
  
.dap-tw{
    &-logo {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
        height: 100%;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        
        @include setStyleMinRwd(1100px){
            width:22px;
            img{
                max-height:22px;
            }
        }
        @include pad{
            width: get-vw-mobile(40px);
            background-color: transparent;
            img{
                max-height: get-vw-mobile(40px);
            }
        }
    }
    
    &-item {
        padding: 0 1em;
        margin-left: 2rem;
        text-decoration: none;
        transition: background-color 0.25s ease;
        overflow: hidden;
    }

    &-text {
        margin-right: 0.15em;
        color: #444343;
        font-weight: 500;

        @include setStyleMinRwd(1200px){
            font-size: get-vw(25px);
        }

        @include setStyleMinRwd(1500px){
            font-size: get-vw(20px);
        }
    
        @include mobile{
            font-size: get-vw-mobile(25px);
        }
    }
}

/* #Media
================================================== */

@include pad{
	.nav{
        &-item{
            &::after{
                display: none;
            }
            &::before {
                position: absolute;
                display: block;
                top: 20px;
                left: 0;
                width: 11px;
                height: 1px;
                content: "";
                border: none;
                background-color: #000;
            }
        }
    }

	.dropdown{
        &-toggle{
            &::after {
                position: absolute;
                display: block;
                top: 15px;
                left: -23px;
                width: 1px;
                height: 11px;
                content: "";
                border: none;
                background-color: #000;
                transition: all 200ms linear;
            }
            &[aria-expanded="true"]{
                &::after{
                    transform: rotate(90deg);
                    opacity: 0;
                }
                &+.dropdown-menu {
                    padding: 0 0 15px 0 !important;
                    margin-top: 5px !important;
                    margin-bottom: 20px !important;
                }
            }
        }
    }
}