.image-slider{
    z-index: 2;
    .swiper-slide {
        position: relative;
        overflow: hidden;
        .index-banner-wrapper {
            @include setStyleMinRwd(800px){
                margin: get-vw(250px) auto get-vw(250px);
            }
        }
        
        @include setStyleRwd(1100px){
            min-height: get-vw(1024px);
        }

        .img-container {
            @extend %setBack;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            padding-bottom: 40%;
            @include mobile{
                position: static;
                height: 200px;
            }
        }
    }

    .content{
        width: 100%;
        margin: auto;
        flex-direction: row;
        justify-content: center;
        @include setStyleMinRwd(1100px){
            max-width: get-vw(1600px);
        }
        
        @include pad1080{
            max-width: get-vw(1700px);
            margin-top: 3rem;
        }

        @include mobile{
            max-width:100%;
            flex-direction: column;
            background: #f0f4f3;
            padding: 1rem;
            margin-top: 0;
        }
    }

    .swiper-pagination{
        .swiper-pagination-bullet{
            width: 15px;
            height: 15px;
            opacity: 1;
            background-color: #fff;
            border: #192563 2px solid;
            &-active{
                background-color: #192563;
            }
        }
    
        @include setStyleMinRwd(1100px){
            max-width: 20rem;
            bottom: vmaxCalc(15) !important;
            left: auto !important;
            right: get-vw(275px);
            text-align: left;
        }

        @include setStyleRwd(1480px){
            right: get-vw(290px);
        }

        @include setWidthRwd(1025px,1100px){
            right: get-vw(140px);
        }

        @include pad1024{
            bottom: get-vw-mobile(50px) !important;
            max-width: 100%;
            margin: 0;
        }

        @include pad{
            bottom: get-vw-mobile(30px) !important;
            max-width: 60%;
        }
    }

    .text-wrapper {
        display: inline-flex;
        .text-inner .sub-title,
        .text-inner .title,
        .text-inner .description{
            transform: translateY(50vw);
            transition: all ease 1s;
        }
    }

    //   /*active states*/
    .swiper-slide-active.active .text-inner .sub-title,
    .swiper-slide-active.active .text-inner .title,
    .swiper-slide-active.active .text-inner .description {
        transform: translateY(0);
        opacity: 1;
    }
    
    //   /*delays*/
    .swiper-slide.active .text-inner .sub-title {
        transition-delay: 0.05s;
    }
    
    .swiper-slide.active .text-inner .title {
        transition-delay: 0.1s;
    }
    
    .swiper-slide.active .text-inner .description {
        transition-delay: 0.15s;
    }
}



.index{
    &-content{
        padding: 120px 2rem;
        @include pad1024{
            padding:1rem 1.2rem;
        }

        // 標題樣式
        .lineStyle-right{
            color:#fff;
            &::after{
                border-bottom-color: #fff;
            }
        }
    }

    // Banner
    &-banner{
        margin-top: get-vw(108px);
        &::after{
            content: '';
            display: block;
            width: 100%;
            min-height: 96px;

            @include setBackSet(bottom,cover);
            background-image: url('../images/banner_bottom.png');

            position: absolute;
            bottom: -28px;
            z-index: 2;
        }

        @include pad{
            margin-bottom: 3rem;
            &::after{
                bottom: -70px;
            }
        }

        @include pad{
            margin-top: get-vw-mobile(85px);
            margin-bottom: 0;
            img{
                height: 200px;
                object-fit: cover;
                object-position: center
            }
            &::after{
                display: none;
            }
        }

        @include mobile{
            margin-top: get-vw-mobile(180px);
        }

        &-title{
            .ch {

                *,
                .font {
                    color: black;
                }

                .font{
                    font-size: get-vw-mobile(40px);
                    padding-left: 5px;
                    padding-right: 5px;
                }

                @include setStyleMinRwd(800px){
                    left: -5.5vw;
                    font-size: get-vw(48px);

                    *,
                    .font {
                        color:#fff;
                    }

                    .font{
                        padding-left: 20px;
                        padding-right: 20px;
                    }
                }

                @include setStyleMinRwd(1100px){
                    .font{
                        font-size: get-vw(60px);
                    }
                }

                @include pad1024{
                    font-size: get-vw-mobile(30px);
                }
            }

            .txt{
                @include fontSize(18px);
                color:#fff !important;
                background: rgba(0,0,0,.65);
                padding: 0.25rem 1rem;
                display: inline-block;
                text-align: center;
                @include setStyleMinRwd(1100px){
                    font-size: get-vw(40px) !important;
                    left: 1.75vw;
                    padding-left: 3rem;
                    padding-right: 3rem;
                    margin-top: 1.5rem;
                }
            }
        }
    }

    // 優勢
    &-advantage{
        @include setBack;
        background-image: url('../images/indexImg-advantage.jpg');
        @include setMinRwd{
            padding-bottom: 15rem;
        }
        
        // 
        .lineStyle-right p {
            padding-right: 3rem;
        }
    }

    // 歷史建案
    &-historyCase{
        display: flex;
        align-items: flex-start;

        @include setBack;
        background-image: url('../images/indexImg-historyCase.jpg');
        
        @include setStyleMinRwd(1100px){
            padding-left: 6rem;
            padding-right: 0;
            padding-bottom: 8rem;
        }

        @include setStyleRwd(1380px){
            padding-left: 1rem;
        }

        @include pad{
            flex-wrap: wrap;
        }
        
        .content{
            border-radius: 10px;
            overflow: hidden;

            // 樣式
            .block-title-box .txt.en{
                padding-left: 0;
            }
            .blockTxt{
                margin-bottom: get-vw(115px);
            }

            .blockSwiper{
                .blockTxt{
                    margin-bottom: 0.5rem;
                }
            }

            // 內容框
            &.img{
                flex:0 0 get-vw(1100px);
                max-width: get-vw(1100px);
                z-index: 1;
                border-bottom-right-radius: 5em;
            }

            &.txt{
                flex-grow: 1;
                background-color: #1b1b1b;

                padding-left: get-vw(230px);
                padding-top: get-vw(150px);
                padding-bottom:get-vw(85px);
                padding-right: get-vw(70px);
                margin-left: get-vw(-168px);

                margin-top: -13rem;
                z-index: 0;
            }
            
            @include pad{
                &.img{
                    flex:0 0 100%;
                    max-width: 100%;
                }

                &.txt{
                    margin-top: 1rem;
                    margin-left: 0;
                    padding: 0.5em 1.75em 1em;
                }
            }
        }

        // Swiper
        .swiper-fun{
            width: 80%;
            display: flex;
            align-items: flex-end;
            z-index: 11;
        }

        .swiper-arrow{
            display: flex;
            align-items: center;
            top: -1.5em;
        }

        .swiper-button{
            &-prev,
            &-next {
                position: static;
                top: -5rem;
                margin-top: 0;
                color:$colorSubs;
                &::after{
                    font-size: 1.85rem;
                }
            }

            &-prev{
                margin-right: 0.75rem;
            }

            &-next{
                margin-left: 0.75rem;
            }
        }

        .swiper-pagination{
            position: static;
            display: flex;
            justify-content: flex-start;
            &-bullet{
                border-radius: 0;
                flex-grow: 1;
                max-width: 6em;
                width: auto;
                height: 1px;
                background: #626262;
                opacity: 1;
                &-active {
                    background-color:$colorSubs;
                }

                @include pad{
                    flex: 1;
                }
            }
        }

        .diver{
            width: 1px;
            height: 20px;
            background: #626262;
            z-index: 2;
        }

        .swiperCase-img{
            &.swiper-container {
                width: 100%;
                height: 100%;
            }
            .swiper-slide {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 0;
                padding-bottom: 57%;
                img{
                    position: absolute;
                    top: 0;
                    transition-duration: 0.8s;
                    transform: scale(1.2);
                    opacity: 0;
                }
                &-active{
                    img{
                        opacity: 1;
                        transform: none;
                    }
                }
            }
        }

        .swiperCase-txt{
            width: 100%;
            overflow: hidden;
            
            .swiper-wrapper{
                transition: 2s cubic-bezier(0.68, -0.4, 0.27, 1.34) 0.2s;
            }
        }

        @include setStyleRwd(1380px){
            .swiper-fun{
                width: 100%;
            }

            .swiper-arrow{
                margin-bottom: 1rem;
            }
        }
    }

    // 服務
    &-service{
        background-color: #010101;
        padding-top: 1rem;
        @include setStyleMinRwd(1100px){
            padding-bottom: 9em;
        }

        .conceptInner {
            position: relative;
            width: 100%;
            max-width: 1400px;
            display: flex;
            flex-wrap: nowrap;
            padding: 0 30px;
            margin: auto;

            @include setStyleMinRwd(1100px){
                .stickyBox {
                    padding-top: 100px;
                }
            }

            @include setMaxRwd{
                flex-wrap: wrap;
                justify-content: space-around;
            }

            @include mobile{
                padding-left: 1em;
                padding-right: 1em;
            }
        }
        
        .text {
            width: 390px;
            position: relative;
            opacity: 1;
            transition: none;
            flex-shrink: 0;
            @include setMaxRwd{
                width: 100%;
            }
        }

        .image{
            width: 950px;
            display: flex;
            flex-wrap: wrap;
            position: relative;
            z-index: 1;
            margin-top: 230px;
            li {
                width: 363px;
                margin-top: 217.8px;
                margin-left: 112px;
                position: relative;
                &:nth-child(-n+2) {
                    margin-top: 0;
                }
                &:nth-child(2n) {
                    transform: translateY(290.4px);
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                .titleWrap{
                    position: absolute;
                    top: auto;
                    left: auto;
                    width: 100%;
                    height: 100%;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    padding: 1em 3.75em;
                    &::before{
                        content: "";
                        display: block;
                        width: calc(100% - 4em);
                        height: calc(100% - 4em);
                        position: absolute;
                        top: 2em;
                        left: 2em;
                        background-color: rgba(255, 255, 255, 0.7);
                        padding: 1em;
                        z-index: 0;
                        opacity: 0;
                    }

                    @include setWidthRwd(1101px,1600px){
                        padding-left:1em;
                        padding-right: 1em;
                        &::before{
                            width: calc(100% - 1em);
                            height: calc(100% - 1em);
                            top: 0.5em;
                            left: 0.5em;
                        }
                    }
                }
                
                .num,
                .title,
                .txt{
                    z-index: 1;
                    transition: 0.5s;
                }

                .num{
                    font-family: "Roboto";
                    font-size: get-vw(60px);
                    color: rgba(255, 255, 255, 0.5);
                    line-height: 1;
                    margin-left: auto;
                    bottom: 0.2em;
                    right: 0.2em;
                }

                .title {
                    position: relative;
                    width: auto;
                    min-width: 60%;
                    color: #fff;
                    font-size: 18px;
                    font-weight: normal;
                    line-height: 2;
                    text-align: center;
                    white-space: nowrap;
                    background: rgba(0,0,0,1);
                    display: block;
                    padding: 3px 3.5em;
                }

                .txt{
                    color:#4c4c4b;
                    line-height: 1.5;
                    margin-top: 1.5em;
                    padding-left: 1.5em;
                    padding-right: 1.5em;
                    position:absolute;
                    transform: translateY(1000%);
                    @include setWidthRwd(1101px,1600px){
                        padding-left: 1em;
                        padding-right: 1em;
                    }
                }

                a {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    box-shadow: 6px 0 24px 8px rgba(0,0,0,0.2);
                    position: relative;
                    overflow: hidden;
                    &:hover,
                    &.active{
                        .titleWrap{
                            &::before{
                                opacity: 1;
                            }
                        }
                        
                        .num{
                            margin-left: 0;
                            right: 0;
                            bottom: 0.2rem;
                            transform: translateX(0px);
                            // mix-blend-mode: color-burn;
                            // color: #d79233;
                            color:rgba(134, 121, 103, 0.8);
                        }

                        .title {
                            background-color: transparent;
                            color:#000;
                            &::after{
                                content: '';
                                display: inline-block;
                                width: 35px;
                                height: 2px;
                                background-color: #5a3616;

                                position: absolute;
                                bottom: -10px;
                                left: 50%;
                                margin-left: -17.5px;
                            }
                        }
                        
                        .txt{
                            position: relative;
                            transform: translateY(0);
                        }
                    }
                }
            }

            @include setStyleRwd(1500px){
                width: calc(100% - 390px);
                display: flex;
                flex-wrap: wrap;
                padding-bottom: calc((42vw - 225px) * 0.8);
                margin-top: 200px;
                li{
                    position: relative;
                    width: 40%;
                    margin-top: calc((42vw - 225px) * 0.6);
                    margin-left: 7.5%;
                    &:nth-child(2n) {
                        transform: translateY(calc((42vw - 225px) * 0.8));
                    }
                }
            }

            @include pad{
                width: 100%;
                padding-left: 0;
                padding-bottom: 2em;
                margin-top: 0px;
                flex: 0 0 auto;
                li{
                    width: 100%;
                    max-width: 360px;
                    margin: 20px auto 0 !important;
                    transform: translateY(0px) !important;
                    .title{
                        display: block;
                        width: 100%;
                        padding-left: 0.5rem;
                        padding-right: 0.5rem;
                    }
                }
            }
        }
        
        // 
        .blockTxt {
            color: #fff;
        }

        // 背景文字
        &::after{
            content: 'INTEGRITY PROFESSION';
            display: block;
            width: 100%;
            font-size: get-vw(160px);
            color:#44362a;
            line-height: 1;
            position: absolute;
            bottom: 0;
            left: 0;
            text-align: center;
            white-space: nowrap;
            opacity: 0.5;
        }
    }
}

.banner-TopArrow-list{
    position: absolute;
    bottom: get-vw(30px);
    right: get-vw(275px);
    z-index: 3;
    width: 105px;
    .banner-TopArrow {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 100%;
        animation: jump 1.5s infinite;
    }

    @include pad1024{
        display: none;
    }
}

@keyframes jump {
    0% {
        bottom: 0;
    }
    50% {
        bottom: 10px;
    }
    100% {
        bottom: 0;
    }
}

@keyframes moveCircle {
    0%,
    100% {
        transform: translateY(-15%);
    }
    50% {
        transform: translateY(-5%);
    }
}